import { createI18n } from 'react-router-i18n';

// Array of supported locales
// The first in the array is treated as the default locale
const locales = ['ru', 'en'];

// Dictionary of translations
const translations = {
    ru: {
        config: {
            btn1: 'Выбрать квартиру',
            btn2: 'получить описание отделки',
            btn3: 'Подобрать <br class="hidden-sm-up"/>по фасаду',
            btn4: 'Подобрать <br class="hidden-sm-up"/>по параметрам',
            btn5: 'получить консультацию',
            file_lbl: 'Размер файла',
            size_lbl: 'Мб',
            file_hint_lbl: 'в формате PDF',
            back: 'назад',
            panoram_lbl: 'ПАНОРАМА',
            logout_lbl: 'Выйти',
            phone_lbl: 'Телефон',
            address_lbl: 'Адрес объекта',
            ct1: 'Москва, Костянский переулок, 13',
            ct2: 'Офис продаж',
            ct3: 'БЦ «Даев Плаза», Даев переулок 20, офис&nbsp;101',
            ct4: 'Время работы',
            ct5: 'Будни 9:00−21:00, выходные 10:00−19:00',
            app_choice_lbl: 'Подбор квартиры',
            app_choice_param_lbl: 'Выбор по Параметрам',
            app_choice_fac_lbl: 'Выбор по фасаду',
            app_fac_arrows_lbl: 'используйте стрелочки <br/>для вращения фасада',
            app_fac_side_lbl: 'Подобрать <br/>по параметрам',
            app_param_side_lbl: 'Подобрать <br/>по фасаду',
            app_param_lbl_1: 'Количество спален:',
            app_param_lbl_2: 'Дополнительно:',
            app_param_lbl_3: 'Терраса',
            app_param_lbl_4: 'Двухуровневые <br/>квартиры',
            app_param_lbl_5: 'Задайте этажи:',
            app_param_lbl_6: 'Задайте площадь (м<sup>2</sup>):',
            app_param_lbl_7: 'Тип',
            app_param_lbl_8: 'Площадь',
            app_param_lbl_9: 'Кол-во спален',
            app_param_lbl_10: 'Спальни',
            app_param_lbl_11: 'Этаж',
            app_param_lbl_12: 'Камин',
            load_more_lbl: 'Загрузить ещё',
            floor_lbl: 'этаж',
            floors_lbl: 'этажи',
            bedrooms_1_lbl: 'спальня',
            bedrooms_2_lbl: 'спальни',
            bedrooms_3_lbl: 'спален',
            build_lbl: 'Секция',
            free_lbl: 'свободно',
            app_1_lbl: 'квартира',
            app_2_lbl: 'квартиры',
            app_3_lbl: 'квартир',
            app_window_lbl: 'окна на',
            post_year_lbl: 'г.',
            error_req_lbl: 'Обязательно к заполнению',
            topic_lbl_1: 'Выберите тему сообщения',
            topic_lbl_2: 'Выбранная из списка тема',
            btn_download_lbl: 'Скачать планировку',
            btn_price_lbl: 'Записаться на просмотр',
            and: 'и',
            llc: 'ООО «Проектно-строительная организация "Неострой"».',
            source_lbl: 'Источник',
            update_lbl: 'Обновить',
            price_lbl: 'Стоимость',
            price_lbl2: 'Стоимость лота'
        },
        nav: {
            item1: 'О проекте',
            item2: 'Новости',
            item3: 'Инфраструктура',
            item4: 'Квартиры',
            item5: 'Пентхаусы',
            item6: 'Покупка',
            item7: 'Партнеры',
            item8: 'Ход строительства',
            item9: 'Брокерам',
            item10: 'Контакты',
            item11: 'СМИ о нас',
            finish: 'ДОМ СДАН'
        },
        partners: {
            pt1: {
                ct: '(Россия)',
                type: 'Финансовый партнер',
                name: 'Сбербанк',
                descr: 'Проектное финансирование. <br/>Эскроу-счета',
                text: '<p>Сбербанк является крупнейшим игроком на&nbsp;рынке ипотечного кредитования с&nbsp;долей рынка более&nbsp;50%.</p><p>Банк дает возможность подачи ипотечной заявки непосредственно в&nbsp;офисе партнера-застройщика, тем самым предлагая уникальный сервис для клиентов.</p>'
            },
            pt2: {
                ct: '(Нидерланды)',
                type: 'Архитектурное бюро',
                name: 'Building Design Architectuur',
                descr: 'Архитектурно-планировочные <br/>решения',
                text: '<p>Партнер с&nbsp;мировым именем по&nbsp;архитектурно-планировочным решениям.</p>'
            },
            pt3: {
                ct: '(Нидерланды)',
                type: 'Архитектурное бюро',
                name: 'Wolterinck',
                descr: 'Концепция дизайна <br/>интерьера',
                text: '<p>Концепция дизайна интерьера</p>'
            },
            pt4: {
                ct: '(Англия)',
                type: 'Архитектурное бюро',
                name: 'Squire & Partners',
                descr: 'Архитектурная <br/>концепция',
                text: '<p>Архитектурная концепция</p>'
            },
            pt5: {
                ct: '(Россия)',
                type: 'Генеральный проектировщик',
                name: 'Финпроект',
                descr: 'Конструктивные решения, <br/>инженерные системы, <br/>разработка рабочей <br/>документации',
                text: '<p>Конструктивные решения, инженерные системы, разработка рабочей документации</p>'
            }
        },
        home: {
            address: 'Сретенка, Костянский переулок, 13',
            contacts: 'Офис продаж: БЦ «Даев Плаза», Даев переулок 20, офис 101 <br/>Адрес объекта: Москва, Костянский переулок, 13 <br/>Телефон для связи:  <br class="hidden-sm-up"/>7 (495) 260-92-27 <br/>Время работы: 9:00 - 21:00, выходные 10:00 - 19:00',
            info: {
                descr: 'Клубный дом Turgenev расположен в&nbsp;самом центре Москвы между улицей Сретенкой и&nbsp;Тургеневской площадью. <br/>В&nbsp;основу заложены принципы респектабельного и&nbsp;обособленного проживания. В&nbsp;окружении тихих переулков обеспечены комфорт и&nbsp;спокойствие, а&nbsp;центральное расположение открывает доступ ко&nbsp;множеству значимых мест Москвы, позволяющих в&nbsp;полной мере насладиться удовольствием от&nbsp;жизни в&nbsp;центре города.',
                item1: {
                    title: '8&nbsp;этажей',
                    descr: 'Ground Floor —  <br/>инфраструктурный этаж, 1-8 этажи - квартиры и пентхаусы.'
                },
                item2: {
                    title: '118 квартир',
                    descr: '2-6 этажи –  <br/>классические квартиры. <br/>7-8 этажи – квартиры <br/>с дровяными каминами, <br/>включая двухуровневые.'
                },
                item3: {
                    title: '167 машиномест',
                    descr: '28 мотомест. <br/>2 этажа подземного <br/>паркинга.'
                },
                item4: {
                    title: '44 кладовых',
                    descr: 'на&nbsp;уровне паркинга, <br/>оборудованных системами <br/>вентиляции, пожаротушения <br/>энергоснабжения и&nbsp;видеонаблюдения.'
                }
            },
            topics: {
                item1: {
                    title: 'О проекте',
                    descr: 'Философия клубного дома. <br/>Описание внешнего облика, <br/>преимущества объекта и отделки. <br/>Проектная документация.'
                },
                item2: {
                    title: 'Инфраструктура',
                    descr: 'Уникальность расположения, которое <br/>открывает доступ ко множеству <br/>значимых мест Москвы, <br/>позволяющих насладиться жизнью.'
                },
                item3: {
                    title: 'Архитектура',
                    descr: 'Фасад первых шести этажей <br/>решён сочетанием вечных материалов песочно-белого натурального камня <br/>и патинированной латуни.'
                },
                item4: {
                    title: 'Новости',
                    descr: 'Фотоотчёты и описание <br/>хода строительства. <br/>Новости проекта. <br/>Упоминание в СМИ.'
                }
            },
            adv: {
                title: 'преимуществА TURGENEV',
                descr1: 'Инфраструктура <br/>пятизвездочного отеля',
                descr2: 'Исторический центр',
                descr3: 'Квартиры с отделкой <br/>(концепция "Тихий дом")',
                descr4: 'Новое строительство',
                descr5: 'Международная <br/>команда архитекторов',
                descr6: 'Собственный <br/>закрытый двор сад'
            }
        },
        about: {
            history: {
                title: 'История района',
                title2: 'История района',
                text1: '<p>Удивительно тихий и&nbsp;уютный уголок в&nbsp;самом центре Москвы расположился между улицей Сретенкой и&nbsp;Тургеневской площадью.</p><p>Сретенка получила своё название по&nbsp;находящемуся на&nbsp;ней Сретенскому монастырю. А&nbsp;монастырь стал так называться в&nbsp;честь важнейшего события российской истории&nbsp;&mdash; встречи (Сретения) перенесенной в&nbsp;Москву из&nbsp;Владимира чудотворной иконы Божией Матери и&nbsp;избавления Москвы от&nbsp;нашествия Тамерлана в&nbsp;1395&nbsp;году.</p>',
                text2: 'До&nbsp;XVIII&nbsp;века, пока Тверская улица не&nbsp;стала частью пути в&nbsp;Северную столицу, главной московской улицей была именно Сретенка.',
                text3: '<p>Строительство нового жилого дома с&nbsp;внутренней инфраструктурой стало уникальными примером редевелопмента территории в&nbsp;центре столицы, среди тихих московских переулков.</p><p>Тургеневская площадь в&nbsp;свою очередь унаследовала название от&nbsp;первой в&nbsp;России публичной библиотеки Ивана Сергеевича Тургенева, построенной в&nbsp;1885&nbsp;году.</p>',
                text4: '<p>Удивительно, что с&nbsp;момента постройки читальня была первым и&nbsp;до&nbsp;2010-х годов, единственным в&nbsp;Москве памятником Тургеневу, давшим к&nbsp;тому&nbsp;же название городской площади.</p><p>Отдавая дань истории центра Москвы, новый клубный дом deluxe-класса между Тургеневской площадью и&nbsp;улицей Сретенкой в&nbsp;Костянском переулке получил значимое и&nbsp;обязывающее имя TURGENEV.</p>'
            },
            about: {
                title: 'О проекте',
                address: 'г. Москва, ЦАО, <br class="hidden-sm-up"/>Костянский переулок, <br class="hidden-sm-up"/>вл. 13 стр. 1'
            },
            arch: {
                title: 'Архитектурный облик',
                title2: 'Облик Архитектуры',
                title3: 'Архитектура',
                descr: 'Внешний облик жилого комплекса являет собой образец качественной современной архитектуры характерной для наиболее дорогих районов европейский столиц. Такому архитектурному языку присущи строгий фасадный ритм, простые, вычищенные до&nbsp;минимализма формы&nbsp;и, в&nbsp;то&nbsp;же время, чрезвычайное внимание к&nbsp;деталям',
                slide1: 'Облицовка фасада — фасад первых шести этажей решён сочетанием вечных материалов песочно-белого натурального камня и патенированной латуни. Панорамные окна в пол — несомненно является отличным интерьерным решением.',
                slide2: 'В облицовке фасада клубного дома TURGENEV используется натуральный камень, португальский мраморовидный известняк марки BRANCO VALONGO. <br/>Камень имеет потрясающие показатели морозостойкости — 240 циклов. Однородный, бежевый, пластичный камень смотрится естественно и основательно.',
                slide3: 'В остеклении квартир Клубного дома TURGENEV используют оконный профиль Schüco AWS 75 — специальная оконная серия со скрытой створкой. <br/>Лаконичный внешний вид по сравнению со стандартной системой не только изнутри, но и снаружи.',
                slide4: 'Скрытая створка системы AWS 75 BS.Si увеличивает световой проём, что дает панорамный вид за счет ощущения более открытого пространства и проникновения большего количества света. <br/>Данное стекло имеет зеркальность  22 %, в связи с чем позволяет обеспечивать достаточный уровень приватности при ярком освещении, а низкий коэффициент внутреннего отражения обеспечивает ясный обзор изнутри помещения.',
                slide5: 'Pilkington Suncool® One 60/40 — cтекло с идеальным балансом светопропускания и солнцезащиты. <br/>По сравнению с обычным стеклом стекло Suncool One 60/40 в 2 раза меньше пропускает солнечного тепла, что позволяет чувствовать себя комфортно в помещении в летнее время.',
                slide6: 'Фасады на 7 и 8 этажах облицованы гранитом Dark Brown - натуральным камнем темно-коричневого оттенка, декоративные свойства которого выразительно подчеркивают строгую элегантность архитектуры и особый статус пентхаусов.',
                slide7: 'Террасы в квартирах на 7 и 8 этажах обрамлены ограждениями из прочного закаленного стекла, которое придает легкость и воздушность верхним этажам дома. Декоративные элементы обшивки террас выполнены в цвете патинированной латуни, гармонично сочетаясь как со стеклом ограждения, так и с фасадным камнем.',
            },
            garden: {
                title: 'ДВОР-САД',
                title2: 'ДВОР-САД',
                descr: 'Закрытая, охраняемая территория внутреннего двора-сада полностью предоставлена проживающим. Двор комплекса &mdash; это настоящий просторный оазис в&nbsp;центре города с&nbsp;зелёным лужайками, ландшафтным освещением, местами для отдыха, мощеными тропинками, уличной лаундж-зоной и&nbsp;кронами больших деревьев, смыкающимися над водными каналами. На&nbsp;территории сада есть WI-FI и&nbsp;точки для зарядки мобильных устройств.'
            },
            finish: {
                title: 'Отделка',
                descr: 'Реализация квартир с&nbsp;финишной чистовой отделкой позволяет соответствовать концепции READY FOR LIVING. <br/>Готовые решения продуманы до&nbsp;мелочей и&nbsp;включают всё необходимое для самого комфортного проживания.'
            },
            lobby: {
                title: 'Лобби',
                descr: '<p>Лобби, ресторан и&nbsp;кинотеатр, винная и&nbsp;сигарная комнаты, wellness и&nbsp;fitness составляют идеальный комплекс общественных помещений, а&nbsp;консьерж-служба и&nbsp;разнообразные коммерческие сервисы довершают картину инфраструктуры клубного дома TURGENEV, которая обеспечивает проживающих всем самым востребованным для комфортной жизни и&nbsp;насыщенного досуга.</p><p>Высокий уровень комфорта поддерживается услугами круглосуточного консьерж-сервиса. Система клубного дома напоминает пятизвездочный отель: любой ваш самый срочный вопрос решается одним звонком на&nbsp;ресепшн.</p>'
            },
            fitness: {
                title: 'Фитнес',
                title_big: 'Fitness, Wellness & SPA',
                descr: '<p>В&nbsp;клубном доме TURGENEV предусмотрено все необходимое для поддержания здорового образа жизни. Около 800м2 инфраструктурного этажа занимает обширная зона фитнеса, велнеса и&nbsp;SPA, которая доступна только для резидентов. Занятия спортом в&nbsp;качественно оборудованной фитнес-зоне или занятия в&nbsp;зале для йоги, прохождение гидротермальной дорожки Кнейпа, посещение сауны или хаммама, спортивный массаж или расслабляющие SPA-процедуры в&nbsp;массажном кабинете, плавание в&nbsp;бассейне&nbsp;&mdash; все это станет приятной ежедневной рутиной для жителей клубного дома TURGENEV. Чтобы посетить эту зону, не&nbsp;придется покидать пределов здания&nbsp;&mdash; можно спуститься на&nbsp;лифте на&nbsp;инфраструктурный этаж и&nbsp;пройти по&nbsp;переходной галерее, которая соединяет все пять секций клубного дома, а&nbsp;бесконтактная технология Face ID&nbsp;сделает посещение максимально комфортным&nbsp;&mdash; магнитные карты и&nbsp;ключи больше не&nbsp;нужны.</p>'
            },
            eng: {
                title: 'инженерия',
                descr: 'Инженерия проекта включает самые современные технологии комфорта и&nbsp;безопасности.',
                item1: {
                    title: 'ВЕНТИЛЯЦИЯ И&nbsp;КОНДИЦИОНИРО<span class="hidden-sm-up">-<br/></span>ВАНИЕ',
                    descr: 'Приточно-вытяжная вентиляция реализована на&nbsp;оборудовании от&nbsp;итальянского концерна &laquo;Euroclima&raquo;. Подаваемый в&nbsp;квартиру воздух предварительно проходит централизованную двухступенчатую очистку, подогрев или охлаждение, затем увлажняется и&nbsp;обеззараживается ультрафиолетовым излучением. Свежий и&nbsp;увлажненный воздух сохраняет красоту волос и&nbsp;эластичность кожи, снижает вероятность проявления аллергических реакций, облегчает дыхание, снижает проявления статического электричества, обеспечивает сохранность картин и&nbsp;книг, деревянных предметов интерьера, мебели и&nbsp;полов. За&nbsp;поддержание комфортной температуры в&nbsp;каждом помещении отвечает VRV-система от&nbsp;японского концерна Daikin&nbsp;&mdash; мирового лидера по&nbsp;производству систем кондиционирования.'
                },
                item2: {
                    title: 'ВОДОСНАБЖЕНИЕ <br/>И&nbsp;КАНАЛИЗАЦИЯ',
                    descr: 'Централизованная система водоподготовки производит фильтрацию воды до&nbsp;уровня &laquo;родниковой&raquo;. Поступающая в&nbsp;дом вода подвергается механической фильтрации, тонкой (до&nbsp;20&nbsp;мкр) и&nbsp;сорбционной очистке (улучшаются органолептические характеристики воды&nbsp;&mdash; вкус, запах и&nbsp;внешний вид), умягчается, ионизируется и&nbsp;проходит антибактериальную обработку уф-излучателями. Приготовленная с&nbsp;использованием такой воды еда и&nbsp;водные процедуры&nbsp;&mdash; полезны для здоровья. Система водоснабжения выполнена трубами из&nbsp;нержавеющей стали швейцарской компании Geberit. Срок службы применяемых труб&nbsp;&mdash; не&nbsp;менее 100&nbsp;лет. Для обеспечения жильцов дома бесперебойным горячим водоснабжением в&nbsp;индивидуальном тепловом пункте (ИТП) на &minus;3 этаже установлены бойлеры общим объемом 6000&nbsp;литров. Бесшумная система канализации выполнена с&nbsp;использованием полимерных труб REHAU, которые отвечают самым высоким гигиеническим нормативам. Насосы, используемые в&nbsp;системах водоснабжения и&nbsp;водоотведения, от&nbsp;датского производителя Grundfos.'
                },
                item3: {
                    title: 'ЭНЕРГОСНАБЖЕНИЕ',
                    descr: 'Надежное электроснабжение всего комплекса обеспечивается двумя независимыми взаимно резервирующими источниками питания. Для защиты электроприборов от&nbsp;вредных воздействий перенапряжения на&nbsp;вводе в&nbsp;каждую секцию главного распределительного щита (ГРЩ) и&nbsp;вводно-распределительных устройств (ВРУ) установлены ограничители перенапряжения. Вводные и&nbsp;отходящие выключатели на&nbsp;ГРЩ и&nbsp;ВРУ выбраны с&nbsp;соблюдением селективности и&nbsp;с&nbsp;защитой от&nbsp;коротких замыканий.'
                },
                item4: {
                    title: 'ЛИФТЫ',
                    descr: 'Лифтовое оснащение проекта предполагает установку лифтов европейского производства с&nbsp;индивидуальным дизайном кабин, без машинного отделения. Бесшумные лифты, готовые доставить жильцов дома прямо от&nbsp;дверей жилища до&nbsp;автомобиля на&nbsp;подземном паркинге. Устанавливается безконтактная вызывная панель (Технология Schindler PORT), которая использует индивидуальные пользовательские сценарии. Лифты компании Schindler зарекомендовали себя лидерами по&nbsp;качеству, надежности и&nbsp;высоким потребительским характеристикам (бесшумность, быстрое открывание дверей).'
                }
            },
            srv: {
                title: 'Сервисы',
                descr: 'Управляющая компания включает в&nbsp;себя самые современные технологии комфорта и&nbsp;безопасности.',
                item1: 'Круглосуточная охрана комплекса и&nbsp;организация пропускного режима',
                item2: 'Контроль доступа, контроль перемещения по&nbsp;территории',
                item3: 'Ежедневная многоразовая уборка входных групп и&nbsp;лифтовых холлов',
                item4: 'Диспетчерский &laquo;on-line&raquo; контроль за&nbsp;работой всех инженерно-технических систем',
                item5: 'Круглосуточный сервис электриков, сантехников и&nbsp;лифтеров',
                item6: 'Ежедневная уборка территории, вывоз мусора, в&nbsp;зимнее время&nbsp;&mdash; уборка и&nbsp;вывоз снега',
                item7: 'Благоустройство и&nbsp;уход за&nbsp;территорией, ландшафтный дизайн',
                item8: 'Служба по&nbsp;уборке квартир',
                item9: 'Уход за&nbsp;детьми',
                item10: 'Видеонаблюдение по&nbsp;всей территории (внешний/внутренний контур, паркинг, двор, общественные зоны, места общего пользования)',
                item11: 'Услуги консьержа, готового в&nbsp;любое время помочь жильцам, встретить и&nbsp;проводить гостей, доставить корреспонденцию',
                item12: 'Услуги личного повара и&nbsp;доставки еды',
                item13: 'Услуги личного водителя',
            },
            safe: {
                title: 'безопасность',
                text1: '<p>За&nbsp;высокий уровень безопасности жителей отвечает комплексная система круглосуточной охраны. Территория комплекса огорожена и&nbsp;оборудована системами контроля персонального доступа с&nbsp;технологией FaceID, регистрации въезда и&nbsp;выезда, а&nbsp;также видео-домофонной связью. Система видеонаблюдения позволяет круглосуточно контролировать наружный периметр дома, все входы в&nbsp;здание, лифты и&nbsp;лифтовые холлы, помещения внутренней инфраструктуры дома, въезд и&nbsp;передвижение по&nbsp;подземному паркингу. Резидентам дома также будет доступен просмотр потокового видео с&nbsp;камер лобби и&nbsp;внутреннего двора, камеры вызывной панели, находящейся перед квартирой, при вызове, прием видео и&nbsp;голосовых вызовов в&nbsp;квартиру с&nbsp;вызывных панелей и&nbsp;пульта консьержа. Технология FaceID также позволит организовать удобную логистику проживающим и&nbsp;в&nbsp;общественных зонах комплекса (нет необходимости брать с&nbsp;собой чип или магнитную карту в&nbsp;фитнес-зону).</p>',
                text2: '<p>Система Умный дом реализована на&nbsp;основе протокола KNX. Приложение Умного дома позволяет реализовать не&nbsp;только все популярные сценарии, включая освещение, управление климатом и&nbsp;безопасность, но&nbsp;и&nbsp;сценарии, когда по&nbsp;нажатию одной кнопки происходит заданная последовательность действий. Например, во&nbsp;время отсутствия хозяев, запускается сценарий, который снизит температуру отопления до&nbsp;16&mdash;18&deg;C, а&nbsp;с&nbsp;16&nbsp;часов начнет плавно поднимать к&nbsp;комфортным 22&deg;C. К&nbsp;моменту возвращения владельцев дома снова будет приятный микроклимат. Предусмотрен вывод данных о&nbsp;состоянии систем и&nbsp;показаний счётчиков на&nbsp;единый пульт или смартфон владельца. Для управления Умным домом в&nbsp;квартире в&nbsp;качестве системы визуализации будет установлен удобный и&nbsp;красивый интерфейс Iridium PRO.</p>'
            },
            comfort: {
                title: 'Комфорт',
                descr: 'Совместное владение жителями дома внутренней инфраструктурой (Sharing Economy) позволяет использовать жилые помещения по&nbsp;основному функциональному назначению, не&nbsp;переплачивая за&nbsp;&laquo;лишние&raquo; метры. Предполагаемый набор общественных помещений, входящих во&nbsp;внутреннюю инфраструктуру Клубного дома TURGENEV, и&nbsp;их&nbsp;функциональность отвечает самым требовательным запросам современных жителей мегаполиса. <br/>Наслаждайтесь комфортной жизнью в&nbsp;Клубном доме TURGENEV!',
                item1: 'Spa & Wellness',
                item2: 'Фитнес-зона',
                item3: 'Комната-трансформер <br/>для частных мероприятий',
                item4: 'Дегустационная <br/>комната',
                item5: 'Ресторан',
                item6: 'Зарядные устройства <br/>для электромобилей',
                item7: 'Сигарная комната с ящиками <br/>для хранения сигар',
                item8: 'Двухуровневый <br/>подземный паркинг',
                item9: 'Кинотеатр <br/>на 12 мест',
                item10: 'Кладовые <br/>помещения',
                item11: 'Уютное лобби <br/>и лаунж-зона',
                item12: 'Место для организации <br/>кейтеринга'
            }
        },
        apps: {
            title: 'Квартиры',
            descr: 'Основополагающими в&nbsp;проекте жилых помещений являлись принципы максимального комфорта проживания, функциональности помещений, статусности, создании приватных зон для всех членов семьи, а&nbsp;так&nbsp;же сбалансированности площади квартир.',
            subtitle: 'Результатом работ голландских архитекторов стали самые удачные планировочные решения, в&nbsp;которых предусмотрено все, чтобы вы&nbsp;чувствовали себя максимально комфортно.',
            list1: 'Просторная зона <br/>кухни-гостиной (50-60 м2)',
            list2: 'Наличие французских балконов и эркеров.',
            list3: 'Просторные санузлы <br/>при мастер-спальнях (10-12 м2).',
            list4: 'Разнообразие планировочных решений, доминирование &laquo;семейных&raquo; форматов в&nbsp;квартирографии проекта.',
            list5: 'Приватная зона в&nbsp;хозяйской спальне (сьют зона включающая спальню, гардеробную, санузел).',
            list6: 'На&nbsp;последних этажах проектом предусмотрены роскошные пентхаусы с&nbsp;террасами и&nbsp;эксплуатируемой кровлей.',
            list7: 'Увеличенное количество окон в&nbsp;семейных <br/>зонах квартир (до&nbsp;4&nbsp;шт).',
            text1: '<p>На 2 уровне паркинга для хранения личных вещей предусмотрены кладовые помещения (площадь 4-6 м2) с&nbsp;удобным доступом для проживающих. Оборудованные инженерными коммуникациями (электричество, вентиляция), системами видеонаблюдения.</p><p>Наличие в&nbsp;комплексе кладовых помещений позволяет эксплуатировать площадь квартиры по&nbsp;основному назначению, не&nbsp;загромождая ее&nbsp;и&nbsp;не&nbsp;переплачивая за&nbsp;избыточную площадь хранения вещей в&nbsp;квартире.</p>',
            app_param_subtitle: 'Реализация квартир с&nbsp;финишной чистовой отделкой позволяет соответствовать концепции READY FOR LIVING. Готовые решения продуманы до&nbsp;мелочей и&nbsp;включают все необходимое для самого комфортного проживания.'
        },
        pent: {
            title1: 'ПЕНТХАУСЫ',
            descr1: 'Пентхаусы Клубного дома Turgenev созданы для тех, кто ценит бескомпромиссный комфорт, ведущие международные архитектурные бюро Wolterinck и&nbsp;Squire&amp;Partners создали изысканный и&nbsp;функциональный дизайн в&nbsp;современном европейском стиле. Качеству материалов и&nbsp;текстурам придано огромное значение. Каждая деталь продумана заранее, чтобы вы&nbsp;наслаждались комфортом с&nbsp;первого дня проживания.',
            title2: '3D тур по Пентхаусу',
            descr2: 'Личная терраса открывает массу возможностей: устроить йогу на&nbsp;рассвете, позвать друзей на&nbsp;барбекю иил насладиться ужином за&nbsp;бокалом вина с&nbsp;видом на&nbsp;исторический центр Москвы. Чистые пруды. Тургеневская площадь. Сретенский монастырь&nbsp;&mdash; самые центральные места столицы оказываются как на&nbsp;ладони, открываясь для вас с&nbsp;новой стороны.',
            slide1: 'Владельцы эксклюзивных лотов на 8 этаже будут иметь выход на собственную террасу на кровле прямо из квартиры. Дизайн-проект для террас разработан голландской студией Wolterinck.',
            slide2: 'На террасах будет свой приватный мини-сад с малыми архитектурными формами, водными элементами, фонтанами, деревьями, кустарниками и цветами, который гармонично сочетается с садом во дворе Клубного дома TURGENEV, также спроектированного студией Wolterinck.',
            slide3: 'Пространство террас гармонично зонировано. Предусмотрена relax-зона c мягкими диванами и столиками в окружении растений, кухонный остров и застекленная летняя столовая.',
            slide32: 'По всему периметру террасы располагаются уличные фонари и подсветка, которые придадут уюта в вечернее время.',
            slide4: 'Личная галерея открывает массу возможностей: устроить йогу на рассвете, позвать друзей на барбекю или насладиться ужином за бокалом вина с видом на исторический центр Москвы.',
            slide5: 'Галереи каждого пентхауса выходят на несколько сторон, где вы можете реализовать разные идеи: поставить шезлонги на южной стороне, чтобы принимать солнечные ванны днем, а стол с комфортными диванчиками - на западной, чтобы наслаждаться закатом во время ужина.',
            slide6: 'Чистые пруды. Тургеневская площадь. Сретенский монастырь - самые центральные места столицы оказываются как на ладони, открываясь для вас с новой стороны.',
            slide7: 'Второй свет придает помещению легкость и простор, делая столовую вашим любимым местом в доме.',
            slide8: 'Полы в квартирах покрыты австрийской паркетной доской. Этот долговечный и надежный в эксплуатации материал лаконично и утонченно дополняет интерьер, а древесная текстура дарит теплые, приятные ощущения при тактильном контакте.',
            slide9: 'Все пространство двухуровневого пентхауса функционально. Открытый коридор на втором этаже станет еще одной локацией для отдыха, где вы сможете насладиться визуальной эстетикой в полной мере.',
            slide10: 'Фасады кухонь на 7 и 8 этажах будут выполнены из шпона серого дуба.',
            slide11: 'Качеству материалов и текстурам уделено огромное внимание. Каждая деталь продумана заранее, чтобы вы наслаждались комфортом с первого дня проживания.',
            slide12: 'Глубокому восстанавливающему силы сну способствует чистый, увлажненный воздух, который в TURGENEV обеспечивает приточно-вытяжная вентиляция итальянского концерна «Euroclima» и система кондиционирования от японского концерна Daikin. В спальне можно выставить желаемую температуру - оптимальной для глубокого сна считается 19-20 градусов.',
            slide13: 'Ванные сделаны из вулканического камня немецкой компанией Knief&Co. Овальная форма ванн, гармонично интегрируется в концепцию дизайна и особенности пространства, а их совершенная эргономичность подарит комфорт при использовании.',
            slide14: 'В отделке санузлов и ванных комнат используется натуральный мрамор природных оттенков – Pirgon, молочный с серыми прожилками и Wooden White, светло-серый с текстурой, напоминающей дерево.',
            slide15: 'Панорамные окна (в семейных зонах квартир их количество увеличено до 4-х) открывают вид на исторический центр Москвы и наполняют все пространство солнечными лучами.',
            slide16: 'Высокая освещенность сохраняется и в темное время суток: ее обеспечивают встроенные системы освещения. А дровяной камин создаст атмосферу комфорта и уюта.',
            slide17: 'Ведущие международные архитектурные бюро Wolterinck и Squire & Partners создали изысканный и функциональный дизайн в современном европейском стиле.',
            slide18: 'Полностью готовые к проживанию решения не только удовлетворят запросы самого взыскательного покупателя, но и позволят полностью исключить длительный период ремонтов, шумных работ и переустройств, обеспечивая возможность комфортного проживания непосредственно после приобретения квартиры.',
            slide19: 'Архитектурное решение делает пространство максимально продуманным. Каждый пентхаус имеет семейную зону: гостиную и кухню, спальню, гардеробную и ванную комнаты.',
            slide20: 'Для тех, кто ценит бескомпромиссный комфорт, предусмотрена круглосуточная работа консьерж-сервиса. Вы можете воспользоваться услугами личного повара и водителя, службой по уборке квартир и даже найти специалистов по уходу за детьми.',
            slide21: 'Просторная ванная комната, оформленная в стиле минимализма, оставляет простор для солнечного света: помимо панорамных окон, обладатели пентхауса получат дополнительный источник естественного света на крыше. Ночью вы сможете любоваться звездным небом.',
            slide22: 'Важность тишины, спокойствия и умиротворенности невозможно переоценить. Концепция клубного дома TURGENEV предполагает реализацию квартир с отделкой высочайшего уровня.',
            slide23: 'С первого дня вы будете окружены тишиной и спокойствием. Реализация квартир с финишной чистовой отделкой позволяет воплотить концепцию «тихий дом», при которой полностью исключается длительный период (по статистике до 5-8 лет с момента ввода дома в эксплуатацию) заселения дома и проведения ремонтно-отделочных работ собственниками помещений.',
            slide24: 'Абсолютным трендом последних лет являются прямоугольные формы помещений с грамотным зонированием – условным делением всего пространства квартиры на общественные и приватные зоны. Во всех лотах TURGENEV просторная – 50-60 кв. м. – общая зона – кухня-столовая гостиная, где всегда приятно собраться на семейные ужины или встретить гостей, а за коридорами расположены приватные зоны – спальни, гардеробные и ванные комнаты.',
            slide25: 'Благодаря широкой площади столовых комнат, место будет всему: от барной стойки, где можно выпить чашку свежего кофе, до винного шкафа с вашей личной коллекцией любимых вин.',
            list_title: 'планировки',
            list: {
                txt1: 'Пентхаус',
                txt2: 'ОБЩАЯ',
                txt3: 'УРОВЕНЬ',
                txt4: 'этаж',
                txt5: 'ПЛОЩАДЬ ЭТАЖА <br/>КВАРТИРЫ БЕЗ УЧЕТА <br/>ГАЛЕРЕЙ',
                txt6: 'Коридор',
                txt7: 'Гостиная',
                txt8: 'Библиотека',
                txt9: 'Постирочная',
                txt10: 'Кухня-столовая',
                txt11: 'Кладовая',
                txt12: 'Санузел',
                txt13: 'Галерея',
                txt14: 'Спальня',
                txt15: 'Ванная',
                txt16: '* указана площадь с понижающим коэффициентом',
                txt17: 'Видовые характеристики:',
                txt18: 'ЧИСТЫЕ ПРУДЫ',
                txt19: 'ТЕАТР СОВРЕМЕННИК',
                txt20: 'м',
                txt21: 'Кухня',
                txt22: 'Кабинет',
                txt23: 'Столовая',
                txt24: 'Терраса',
                txt25: '* указана фактическая площадь',
                txt26: 'ПЛОЩАДЬ ЭТАЖА <br/>КВАРТИРЫ БЕЗ УЧЕТА <br/>ТЕРРАСЫ',
                txt27: 'Гардеробная',
                txt28: 'ХРАМ СВЯТИТЕЛЯ НИКОЛАЯ',
                txt29: 'Столовая',
                txt30: 'ТЕРРАСА <br/>НА КРОВЛЕ',
                txt31: 'Терраса на кровле',
                txt32: 'Прихожая-холл',
                txt33: 'Прихожая',
                txt34: 'Общая площадь квартиры',
                txt34br: 'Общая площадь квартиры',
                txt35: 'Световые порталы <br/>в потолке',
                txt36: '3 спальни, гостиная и кабинет',
                txt37: 'Кухня-гостиная',
                txt38: 'Ванная комната',
                txt39: '2 спальни, гостиная',
                txt40: 'Общ. площадь',
                txt41: 'ПЛ.&nbsp;КВАРТИРЫ БЕЗ ГАЛЕРЕЙ',
                txt42: 'Лестница'
            }
        },
        buy: {
            title1: 'Статус квартир',
            title4: 'Договор купли-продажи (ДКП)',
            title2: 'Рассрочка',
            title3: 'Ипотека',
            descr1: 'После завершения строительства и&nbsp;ввода объекта Клубный дом TURGENEV в&nbsp;эксплуатацию в&nbsp;декабре 2023 года все квартиры оформлены в&nbsp;собственность застройщика.',
            descr2: 'Квартиры в&nbsp;клубном доме Turgenev можно приобрести в&nbsp;рассрочку. <br/>Выплата возможна на&nbsp;ежемесячной основе.',
            descr4: 'Сделки с&nbsp;клиентами проводятся по&nbsp;договору купли-продажи (ДКП), что обеспечивает юридическую чистоту и&nbsp;безопасность сделок.'
        },
        brokers: {
            title: 'Уважаемые брокеры!',
            descr1: 'Приглашаем вас к&nbsp;сотрудничеству по&nbsp;продаже квартир в&nbsp;готовом жилом комплексе deluxe-класса. Мы&nbsp;всегда рады новым партнерам в&nbsp;лице агенств по&nbsp;недвижимости и&nbsp;частных брокеров. Преимущества работы с&nbsp;нами:',
            descr2: 'По вопросу сотрудничества пишите <a href="mailto:nepeyvoda@turgenevdom.ru">nepeyvoda@turgenevdom.ru</a>.',
            list1: 'Предоставляем все рекламные материалы по&nbsp;проекту.',
            list2: 'Предоставляем информацию <br/>о&nbsp;ходе сделки с&nbsp;клиентом.',
            list3: 'Проводим регулярные <br/>брокер-туры для ваших <br/>представителей.',
        },
        infra: {
            title: 'Инфраструктура',
            subtitle1: 'Внешняя',
            subtitle2: 'Внутренняя',
            descr: '<p>В основу клубного дома TURGENEV заложены принципы респектабельного и обособленного проживания.</p><p>В окружении тихих сретенских переулков обеспечены комфорт и спокойствие, а центральное расположение открывает доступ ко множеству значимых мест Москвы, позволяющих в полной мере насладиться удовольствием от жизни в самом центре города.</p>',
            out: {
                cat1: 'Парки и скверы',
                cat2: 'Театры',
                cat3: 'Музеи',
                cat4: 'Рестораны',
                cat5: 'Спорт',
                cat6: 'Торговые центры',
                cat7: 'Образование',
                cat8: 'Медицина',
                place1: {
                    title: 'Сретенский бульвар',
                    descr: 'Живописный бульвар в&nbsp;самом центре Москвы, протянувшийся от&nbsp;площади Сретенские Ворота до&nbsp;Тургеневской площади, который так и&nbsp;манит зеленью, романтичной атмосферой и&nbsp;уютом. Прогуляться вечером, послушать звуки природы и&nbsp;насладиться тихим мгновеньем&nbsp;&mdash; отличный отдых от&nbsp;шумных будней.'
                },
                place2: {
                    title: 'Ильинский сквер',
                    descr: 'Один из&nbsp;старейших скверов, расположившийся в&nbsp;центре шумного мегаполиса. Это тихий и&nbsp;уютный зеленый островок с&nbsp;многолетней историей. Почитайте книгу на&nbsp;лавочке, послушайте музыку или подкаст, отдохните&nbsp;&mdash; и&nbsp;вы&nbsp;сразу почувствуете, как исчезает городская суета.'
                },
                place3: {
                    title: 'Парк Горка',
                    descr: 'Необычный парк с&nbsp;многоярусной зоной, где жители и&nbsp;гости столицы смогут скрасить свой досуг. Зона отдыха расположена в&nbsp;центре Москвы и&nbsp;создана по&nbsp;инициативе местных жителей в&nbsp;2017&nbsp;году.'
                },
                place4: {
                    title: 'Сад Эрмитаж',
                    descr: 'В&nbsp;этом саду можно почувствовать, как в&nbsp;воздухе повсюду витает искусство. И&nbsp;это не&nbsp;просто так: Эрмитаж основан театральным деятелем Я.&nbsp;В.&nbsp;Щукиным, а&nbsp;потому на&nbsp;территории парка есть театры и&nbsp;сценические площадки. Они помнят исторические выступления известных в&nbsp;России и&nbsp;мере деятелей искусства.'
                },
                place5: {
                    title: 'Цветной Бульвар',
                    descr: 'Совсем недалеко от&nbsp;Кремля располагается улица с&nbsp;богатым историческим прошлым. Это городская артерия, соединяющая Бульварное кольцо с&nbsp;Садовым, предоставляет москвичам и&nbsp;гостям столицы отправиться в&nbsp;путешествие в&nbsp;прошлое, проходя вдоль старинных зданий.'
                },
                place6: {
                    title: 'Парк Зарядье',
                    descr: 'Нестандартные архитектурные решения и&nbsp;впечатляющие достижения в&nbsp;области ландшафтного дизайна не&nbsp;смогут оставить вас равнодушными. Это футуристическое место с&nbsp;&laquo;парящим&raquo; мостом над Москвой-рекой с&nbsp;одной стороны и&nbsp;настоящая аллея с&nbsp;растениями четырёх природных зон России с&nbsp;другой.'
                },
                place7: {
                    title: 'Александровский сад',
                    descr: 'Верхний, Средний и&nbsp;Нижний сады&nbsp;&mdash; гармоничное переплетение древней истории и&nbsp;природы, величественной архитектуры и&nbsp;душевной атмосферы. Гуляя по&nbsp;саду, вы&nbsp;почувствуете себя главным героем романа 19&nbsp;века, ведь открывающиеся перед вами будто сбежали со&nbsp;страниц книги.'
                },
                place8: {
                    title: 'Чистые пруды',
                    descr: 'Это место хранит в&nbsp;себе самые разные настроения. Старинная Москва со&nbsp;скверами, переулками и&nbsp;архитектурными памятниками навеет умиротворённость и&nbsp;спокойствие. А&nbsp;пульсирующий ритм мегаполиса, творческая и&nbsp;деловая среда дают энергию и&nbsp;внутренние силы. Выбирайте, что больше подходит вам.'
                },
                place9: {
                    title: 'Сквер на Сухаревской',
                    descr: 'Сквер, где зеленые аллеи и&nbsp;архитектура гармонично сочетаются и&nbsp;только дополняют друг друга. Круглая площадка, скрытая среди деревьев и&nbsp;напоминающая &laquo;зеленый кабинет&raquo;,&nbsp;&mdash; отличное место для вашей фотосессии. А&nbsp;любая прогулка или утренняя пробежка станет еще приятнее среди таких живописных видов.'
                },
                place10: {
                    title: 'Парк Мосгордумы. Или «Екатерининская больница».',
                    descr: 'Небольшой зеленый уголок на&nbsp;Страстном бульваре на&nbsp;территории Московской городской думы&nbsp;&mdash; это сочетание утонченной аристократичности и&nbsp;отличного места для проведения досуга. Парк может похвастаться изумрудными газонами и&nbsp;лаконичными клумбами, которые запросто вдохновят художника написать прекрасную картину.'
                },
                place11: {
                    title: 'Сквер на&nbsp;Страстном бульваре',
                    descr: 'Пока вы&nbsp;будете идти вдоль вековых деревьев и&nbsp;наслаждаться их&nbsp;красотой и&nbsp;приятным ощущением старины, вы&nbsp;можете стать свидетелей настоящего шахматного турнира. Здесь сходятся несколько поколений, которые объединяет одна игра. Попробуйте сделать свой королевский гамбит.'
                },
                place12: {
                    title: 'Театр Олега Табакова',
                    descr: 'Театр, названный в&nbsp;честь талантливого актера и&nbsp;режиссера Олега Табакова, известен своими репертуарами не&nbsp;только в&nbsp;России, но&nbsp;и&nbsp;за&nbsp;рубежом. На&nbsp;этой сцене выступают передовые звезды театра и&nbsp;кино, и&nbsp;у&nbsp;вас есть шанс увидеть это и&nbsp;стать свидетелем живой истории'
                },
                place13: {
                    title: 'Театр Маяковского',
                    descr: 'Сретенская сцена одного из&nbsp;самых старых и&nbsp;прославленных коллективов России. Имена выдающихся режиссёров, актёров, художников, творивших историю театра и&nbsp;творящих сегодня на&nbsp;его сцене, живут в&nbsp;памяти поколений и&nbsp;по&nbsp;праву заполняют страницы книг, статей и&nbsp;рецензий. Оказавшись там единожды, вы&nbsp;навсегда пускаете этот театр в&nbsp;свое сердце.'
                },
                place14: {
                    title: 'Большой театр',
                    descr: 'Это сердце культуры и&nbsp;искусства России, куда съезжаются со&nbsp;всех уголков планеты, чтобы вживую лицезреть легендарные постановки. Театр удовлетворяет потребность зрителя в&nbsp;классическом искусстве, просвещает и&nbsp;формирует вкус. Здесь вы&nbsp;почувствуете себя частичкой чего-то особенного и&nbsp;волшебного.'
                },
                place15: {
                    title: 'Современник',
                    descr: 'Театр, основанный в&nbsp;1956&nbsp;году, ставил своей главной задачей говорить на&nbsp;языке современности. &laquo;Современник&raquo; сохранил этот постулат и&nbsp;пронёс его сквозь десятилетия для следующих поколений. Это место, где старое начинает говорить по-новому, где связь между зрителем и&nbsp;сценой ценна как нигде больше.'
                },
                place16: {
                    title: 'Театр Олега Табакова',
                    descr: 'Чистопрудная сцена легендарного театра Олега Табаковка, на&nbsp;которой ставились именитые спектакли и&nbsp;росли большие таланты. Погрузиться в&nbsp;атмосферу творческого пространства особенно интересно с&nbsp;открытием нового сезона.'
                },
                place17: {
                    title: 'МХТ им. А. П. Чехова',
                    descr: 'Антон Павлович Чехов внес неоценимый вклад в&nbsp;русскую литературу. У&nbsp;его идей, мыслей и&nbsp;принципов было множество последователей, и&nbsp;МХТ им. А.&nbsp;П.&nbsp;Чехова позволяет полностью этими идеями проникнуться. Театр предлагает вам познакомиться с&nbsp;писателем и&nbsp;узнать его лучше.'
                },
                place18: {
                    title: 'Театр ШДИ',
                    descr: 'Место с&nbsp;концепцией театра-лаборатории, где приоритеты&nbsp;&mdash; поиск и&nbsp;исследования с&nbsp;возможностью постоянного эксперимента. Непохожесть на&nbsp;другие театры делает его особенным и&nbsp;привлекает зрителей. Посетив любой спектакль, вы&nbsp;погрузитесь в мир совершенно нового иного искусства.'
                },
                place19: {
                    title: 'Малый театр',
                    descr: 'Старейший театр России, где современное поколение артистов и&nbsp;режиссеров отличается приверженностью к&nbsp;его богатым традициям. Если вы&nbsp;хотите прочувствовать настоящую театральную атмосферу, то&nbsp;поторопитесь купить билет, ведь разлетаются они мгновенно.'
                },
                place20: {
                    title: 'El cetera',
                    descr: 'Это живой открытый театр, существующий вне каких-либо догматов и&nbsp;жестких идей и&nbsp;не&nbsp;боящийся открывать новые названия, театральные идеи и&nbsp;формы. Современно, интересно и&nbsp;неожиданно: в&nbsp;этом театре вас будут переполнять самые разные, но&nbsp;такие яркие эмоции.'
                },
                place21: {
                    title: 'Манеж',
                    descr: 'Один из&nbsp;главных выставочных залов столицы приглашает вас познакомиться с&nbsp;выставками и&nbsp;ярмарками со&nbsp;всех уголков нашей страны. Будучи свидетелем многолетней истории, здание Манежа и&nbsp;по&nbsp;сей день находится в&nbsp;центре событий.'
                },
                place22: {
                    title: 'X-Fit Чистые пруды',
                    descr: 'Фитнес-клуб с&nbsp;высокой репутацией в&nbsp;самом центре Москвы предлагает вам уникальную систему тренировок и&nbsp;тренажёры от&nbsp;ведущих мировых брендов. Не&nbsp;важно, какая цель: похудение, набор мышечной массы или поддержание тонуса&nbsp;&mdash; вам помогут их&nbsp;достигнуть, чтобы вы&nbsp;были на&nbsp;вершине чувств от&nbsp;ощущения своего тела.'
                },
                place23: {
                    title: 'World Class Оружейный',
                    descr: 'Это место силы в&nbsp;центре Москвы для тех, кто хочет погрузиться в&nbsp;мир осознанных физических практик вместе с&nbsp;лучшими экспертами в&nbsp;этой области. Это пространство, где все способствует тому, чтобы перезагрузиться и&nbsp;забыть о&nbsp;повседневной суете.'
                },
                place24: {
                    title: 'Savoy Чистые пруды',
                    descr: 'Современный Wellness-клуб в&nbsp;центре Москвы&nbsp;&mdash; это комфорт и&nbsp;внимание к&nbsp;деталям в&nbsp;сочетании с&nbsp;оснащенным тренажерным залом. В&nbsp;итоге вы&nbsp;получаете идеальную атмосферу для тренировок, а&nbsp;это&nbsp;то, что нужно для восстановления сил.'
                },
                place25: {
                    title: 'ГУМ',
                    descr: 'Один из&nbsp;самых известных универмагов не&nbsp;только России, но&nbsp;и&nbsp;всего постсоветского пространства. Это не&nbsp;только фешенебельный торгово-развлекательный комплекс, это настоящий арт-объект, притом речь идет как о&nbsp;внутреннем содержании ГУМа, так и&nbsp;о&nbsp;внешнем его виде. Выполненное в&nbsp;псевдорусском стиле здание стало таким&nbsp;же символом России, как собор Василия Блаженного или Кремль.'
                },
                place26: {
                    title: 'ЦДМ',
                    descr: 'Дети со&nbsp;всей страны мечтают хоть раз побывать здесь. В&nbsp;этом сказочном универмаге на&nbsp;Лубянке любой ребенок сможет почувствовать себя счастливее, ведь здесь собраны все детские магазины с&nbsp;самыми разнообразными игрушками.'
                },
                place27: {
                    title: 'Центральный рынок',
                    descr: 'Слово &laquo;качество&raquo; поистине является синонимом этого рынка. Фермерские продукты лучших производителей станут украшением и&nbsp;изюминкой ваших блюд. Приходите не&nbsp;только за&nbsp;продуктами, но&nbsp;и&nbsp;за&nbsp;досугом: будь&nbsp;то семейный завтрак или обед с&nbsp;друзьями после прогулки по&nbsp;центру.'
                },
                place28: {
                    title: 'Петровский пассаж',
                    descr: 'Торговый комплекс в&nbsp;элегантном здании 19&nbsp;века предоставляет вам возможность элитного шопинга посреди аристократичных интерьеров. Здесь вы&nbsp;почувствуете себя особенным, радуя себя покупками в&nbsp;магазинах с&nbsp;сервисом высшего качества.'
                },
                place29: {
                    title: 'Пассаж Кузнецкий мост',
                    descr: 'Здание торгового центра построено в&nbsp;19&nbsp;веке в&nbsp;стиле &laquo;ампир&raquo;. Сейчас&nbsp;же оно стилизовано, сохраняет красивую лепнину, витрины и&nbsp;фасады. Если отправитесь туда на&nbsp;шопинг, то&nbsp;точно сможете почувствовать себя кем-то из&nbsp;знатного рода.'
                },
                place30: {
                    title: 'Универмаг Цветной',
                    descr: 'Универмаг в&nbsp;центре Москвы, мимо которого вам будет сложно пройти, не&nbsp;зайдя внутрь. Помимо магазинов, особенностью места является пространство ARTBasement, которое объединило формат галереи, лектория, кинозала и&nbsp;места для проведения мероприятий, связанных с&nbsp;популяризацией современного искусства и&nbsp;моды.'
                },
                place31: {
                    title: 'Пушкинский лицей №1500',
                    descr: 'Открытый к&nbsp;200-летию поэта Александра Пушкина лицей продолжает дело знаменитого Царскосельского лицея. Здесь готовят будущих специалистов по&nbsp;самым высоким стандартам.'
                },
                place32: {
                    title: 'Школа №1284 иностранных языков им. Н.&nbsp;Ковшовой',
                    descr: 'Это современная школа с&nbsp;углубленным изучением языков особенна своей доступностью качественного образования. В&nbsp;этой школе ваш уровень английского будет гарантированно высоким.'
                },
                place33: {
                    title: 'НИУ ВШЭ',
                    descr: 'Спросите старшеклассников, куда они хотят поступить, многие скажут, что &laquo;Вышка&nbsp;&mdash; это мечта&raquo;. Так оно и&nbsp;есть: это место, где учёба априори не&nbsp;может быть скучной. Современные подходы к&nbsp;обучению и&nbsp;безграничные возможности притягивают школьников со&nbsp;всей страны.'
                },
                place34: {
                    title: 'Московский архитектурный институт',
                    descr: 'Главный архитектурный вуз России, имеющий 250-летнюю историю и&nbsp;готовящий архитекторов и&nbsp;дизайнеров высшей квалификации. Если выбрали путь архитектора, то&nbsp;лучше места, где вас смогут обучить этой профессии, вам не&nbsp;найти.'
                },
                place35: {
                    title: 'Лицей НИУ ВШЭ',
                    descr: 'Лицей от&nbsp;первого вуза страны уже звучит престижно, в&nbsp;реальности это тоже так. Занимая высокое место в&nbsp;рейтинге лучших школ Москвы, лицей подтверждает этот статус, предлагая специальные программы обучения, рассчитанные на&nbsp;раскрытие вашего потенциала.'
                },
                place36: {
                    title: 'Французский лицей имени А. Дюма',
                    descr: 'Лицей под патронажем посольства Франции открыт для граждан Франции, живущих в&nbsp;Москве. Обучение происходит в&nbsp;лучших французских традициях, предоставляя лицеистам многовековое качество.'
                },
                place37: {
                    title: 'Российская академия живописи И.&nbsp;Глазунова',
                    descr: 'Если вы&nbsp;любите искусство и&nbsp;хотели&nbsp;бы, чтобы оно стало делом вашей жизни, то&nbsp;подумайте об&nbsp;этом вузе. Академия возрождает традиции изобразительного искусства, а&nbsp;преподаватели и&nbsp;специальная программа обучения сделают из&nbsp;вас специалиста'
                },
                place38: {
                    title: 'Университет Управления при Правительстве Москвы',
                    descr: 'Карьера в&nbsp;сфере управлении будет успешной, если в&nbsp;этом вузе, основателем которого является Правительство Москвы. Использование VR&nbsp;технологий разнообразят ваше обучение, а&nbsp;&laquo;двойной диплом&raquo; совместно с&nbsp;Пражским университетом позволит вам носить звание международного специалиста'
                },
                place39: {
                    title: 'Частный лицей “Современное образование”',
                    descr: 'В&nbsp;этом лицее сочетаются современные технологии и&nbsp;классическое образование. Здесь у&nbsp;вас будет дисциплина, ответственность и&nbsp;неоценимый багаж знаний, который поможет вам поступить в&nbsp;лучшие вузы России.'
                },
                place40: {
                    title: 'ЦУМ',
                    descr: 'Первый и&nbsp;главный department store страны. Универмаг по&nbsp;праву стал одним из&nbsp;любимых туристических мест. Оказавшись там, вас поразит масштаб и&nbsp;разнообразие дизайнерских решений, среди которых сотни мировых брендов, предлагающих продукцию наивысшего качества.'
                },
                place41: {
                    title: 'МГУУ',
                    descr: 'Один из&nbsp;ведущих вузов Москвы по&nbsp;подготовке профессионалов в&nbsp;управлении, а&nbsp;также юристов и&nbsp;специалистов международных отношений. Благодаря компетентному преподавательскому составу и&nbsp;программе обучения, вы&nbsp;точно станете лучшим из&nbsp;лучших в&nbsp;выбранной профессии.'
                },
                place42: {
                    title: 'НИИ Скорой Помощи им.&nbsp;Н.В.&nbsp;Склифосовского',
                    descr: 'Институт основан в&nbsp;1923 году на&nbsp;базе одной из&nbsp;старейших московских больниц и&nbsp;принимает в&nbsp;год до&nbsp;67&nbsp;000 пациентов разной сложности заболеваний. Постоянные исследования и&nbsp;поиски новых методов лечения спасают жизни десяткам тысяч пациентов по&nbsp;всей стране.'
                },
                place43: {
                    title: 'Поликлиника РАН',
                    descr: 'Разнообразие направлений этой поликлиники поражает. От&nbsp;кардиологии до&nbsp;неврологии: специалисты высшей квалификации гарантируют вам современные и&nbsp;успешные методики лечения даже самых сложных болезней.'
                },
                place44: {
                    title: 'Сандуны',
                    descr: 'Если хотите узнать, что такое настоящая русская баня, то&nbsp;лучше места вы&nbsp;не&nbsp;найдете. Сандуны&nbsp;&mdash; это место, в&nbsp;которое хочется возвращаться снова, чтобы почувствовать, как усталость растворяется в&nbsp;воздухе, а&nbsp;взамен приходит полное расслабление и&nbsp;приятное тепло в&nbsp;каждой клеточке тела.'
                },
                place45: {
                    title: 'Политехнический музей',
                    descr: 'Один из&nbsp;старейших научно-технических музеев мира, расположен в&nbsp;Москве на&nbsp;Новой площади. Образован в&nbsp;1872 году по&nbsp;инициативе Общества любителей естествознания, антропологии и&nbsp;этнографии при активном участии профессоров Московского университета Григория Щуровского, Анатолия Богданова и&nbsp;Августа Давидова.'
                },
                place46: {
                    title: 'ММАМ',
                    descr: 'Музей, после которого вы&nbsp;посмотрите на&nbsp;искусство по-новому. Здесь не&nbsp;боятся самовыражения и&nbsp;постоянно находятся в&nbsp;поиске уникальных свежих тенденции в&nbsp;области фото и&nbsp;медиа-искусства.'
                },
                place47: {
                    title: 'Рыба моя',
                    descr: 'Знатоки говорят: чтобы перепробовать все виды рыбы и&nbsp;морепродуктов, придется ходить сюда каждый вечер два месяца. Мы&nbsp;думаем, что это отличная идея&nbsp;&mdash; ведь от&nbsp;вашего дома до&nbsp;ресторана рукой подать.'
                },
                place48: {
                    title: 'Riesling Boyz',
                    descr: 'Дмитрий Левицкий и&nbsp;Гоша Карпенко создали рислинговый бар в&nbsp;Москве. Днем заведение работает как ресторан европейской кухни, а&nbsp;вечером&nbsp;&mdash; как pre-party бар с&nbsp;музыкой на&nbsp;виниле.'
                },
                place49: {
                    title: 'Lila',
                    descr: 'Новое модное заведение располагается в&nbsp;двухэтажном особняке на&nbsp;Сретенке. Здесь можно насладиться паназиатской кухней Lila Asia, итальянской кухней Lila Italy, побаловать себя сладким в&nbsp;кафе-кондитерской Lila Pastry и&nbsp;расслабиться в&nbsp;баре Insider.'
                },
                place50: {
                    title: 'Китайская грамота',
                    descr: 'Очередной успешный проект Александра Раппопорта с&nbsp;высшими оценками во&nbsp;всех гастрономических рейтингах Москвы. Кантонская кухня в&nbsp;свежем обличии.'
                },
                place51: {
                    title: 'Эклерная Клер',
                    descr: 'Чизкейки, брусничные пироги, шведские булочки с&nbsp;кардамоном&nbsp;и, конечно&nbsp;же, эклеры&nbsp;&mdash; рядом с&nbsp;клубным домом TURGENEV располагается одна из&nbsp;самых милых и&nbsp;уютных кофеен.'
                },
                place52: {
                    title: 'Maurizio',
                    descr: 'Маурицио Пиццути представляет уникальный гастрономический проект, который расположился неподалеку от&nbsp;клубного дома TURGENEV. Здесь вы&nbsp;сможете насладиться главными удовольствиями Италии: традиционной кухней, роскошной винотекой и&nbsp;кондитерской с&nbsp;настоящими итальянскими десертами.'
                },
                place53: {
                    title: 'Olluco',
                    descr: 'Кухня шеф-повара Вирхилио Мартинеса в Перу демонстрирует биоразнообразие территорий и следует девизу «За пределами всегда есть нечто большее». Olluco - это результат стремления выходить за рамки, исследовать, экспериментировать с новыми впечатляющими ингредиентами по качеству и происхождению.'
                }
            },
            in: {
                cat1: {
                    title: 'Лобби',
                    descr: 'Оказавшись в&nbsp;просторном лобби, где вы&nbsp;почувствуете себя членом королевской семьи, вас встретит Портье и&nbsp;поможет вам донести сумки или тяжелые пакеты. Уютное пространство с&nbsp;роскошной отделкой из&nbsp;мрамора, высокими потолками и&nbsp;уникальным освещением создают неповторимую атмосферу комфорта и&nbsp;изящества. А&nbsp;утонченный дизайн в&nbsp;сочетании с&nbsp;последним словом технологии и&nbsp;высококлассным сервисом приятно удивят и&nbsp;раскроют невиданные ранее чувства.'
                },
                cat2: {
                    title: 'Консьерж-сервис',
                    descr: 'Встретить и&nbsp;проводить гостей, доставить корреспонденцию; подобрать для вас службу по&nbsp;уборке квартир, личного повара и&nbsp;водителя или даже няню для детей.'
                },
                cat3: {
                    title: 'Фитнес зона',
                    descr: 'Сделать фитнес регулярной частью своей жизни гораздо проще, когда он&nbsp;доступен в&nbsp;любое время. В&nbsp;клубном доме TURGENEV в&nbsp;вашем распоряжении будет приватная фитнес-зона с&nbsp;доступом к&nbsp;силовому, кардио и&nbsp;функциональному тренингу.'
                },
                cat4: {
                    title: 'Бассейн',
                    descr: 'Две дорожки длиной в&nbsp;23&nbsp;метра&nbsp;&mdash; ключевой и&nbsp;любимый ритуал каждого утра. Время, когда вы&nbsp;предоставлены самому себе. Превосходный вариант начала дня, после которого все запланированное на&nbsp;день удается воплотить с&nbsp;большим энтузиазмом.'
                },
                cat5: {
                    title: 'SPA & Welness',
                    descr: 'Восстановиться после спорта, а&nbsp;также наградить себя за&nbsp;проделанную работу вы&nbsp;сможете в&nbsp;SPA-центре. Спортивный и&nbsp;расслабляющий массажи подарят дополнительный заряд энергии, и&nbsp;ваше тело скажет &laquo;спасибо&raquo;. А&nbsp;пока за&nbsp;окном будут холода, вы&nbsp;всегда сможете погреться в&nbsp;сауне или&nbsp;же после тяжелого дня расслабиться в&nbsp;хаммаме.'
                },
                cat6: {
                    title: 'Винотека и сигарная комнаты',
                    descr: 'Сигарная и винная комнаты отлично подойдут для завершения удачной сделки, совместного обсуждения грандиозных планов и снятия напряжения после трудного дня.'
                },
                cat7: {
                    title: 'Ресторан',
                    descr: 'От&nbsp;лучших гастрономических произведений вас будут отделять всего несколько этажей: нужно лишь спуститься, чтобы насладиться лучшей кухней. Встречи с&nbsp;друзьями, партнерами или просто приятные семейные вечера в&nbsp;уютном ресторане, не&nbsp;отходя от&nbsp;вашего дома. Особенно актуально это летом: вы&nbsp;сможете наслаждаться бокалом вина на&nbsp;открытой веранде, в&nbsp;окружении оазиса зелени в&nbsp;вашем ландшафтном саду.'
                },
                cat8: {
                    title: 'Кинотеатр',
                    descr: 'В&nbsp;клубном доме TURGENEV в&nbsp;вашем распоряжении будет личный кинотеатр на&nbsp;12&nbsp;мест, в&nbsp;котором вы&nbsp;вместе с&nbsp;друзьями сможете посмотреть не&nbsp;только любимые фильмы, но&nbsp;и&nbsp;футбольный матч, что точно позволит вам погрузиться в&nbsp;атмосферу на&nbsp;все 100%'
                },
                cat9: {
                    title: 'Переговорная',
                    descr: 'Деловая встреча проходит успешнее, когда вы чувствуете себя на своей территории. А неформальная обстановка помогает расположить собеседника и закрепить успех переговоров. Собственная переговорная комната станет удобным местом для ведения официальной части встреч, а сигарная комната отлично подойдет, чтобы отметить завершение удачной сделки.'
                },
                cat10: {
                    title: 'Управляющая компания',
                    descr: 'За&nbsp;безупречным обслуживанием всегда стоит большая команда, даже если ее&nbsp;не&nbsp;видно. Для вашего комфорта управляющая компания обеспечивает круглосуточный сервис электриков, сантехников и&nbsp;лифтеров; ежедневную уборку территории, вывоз мусора и&nbsp;снега; а&nbsp;садовники создадут поистине волшебную атмосферу внутреннего двора.'
                }
            }
        },
        news: {
            title: 'Новости проекта'
        },
        mass: {
            title: 'СМИ о нас'
        },
        footer: {
            pr_lbl: 'Проект «Наш Дом»',
            policy_lbl: 'Политика конфиденциальности',
            copyright: 'Содержимое данного сайта (включая размещенную информацию и&nbsp;материалы) охраняется авторским правом <br/>(ст.&nbsp;1271&nbsp;ГК РФ). Запрещено копирование дизайна настоящего сайта, его структуры и&nbsp;отдельных элементов без предварительного письменного согласия ООО Проектно-строительная организация &laquo;Неострой&raquo;.',
            decl_lbl: 'Проектная <br/>декларация',
            const_lbl: 'Разрешение <br/>на строительство',
            state_reg_lbl: 'Свидетельство <br/>о государственной <br/>регистрации права',
            ddu_reg_lbl: 'Проект ДДУ',
            vvod_reg_lbl: 'Разрешение на&nbsp;ввод объекта в&nbsp;эксплуатацию'
        },
        modals: {
            design: {
                title: 'ПОЛУЧИТЬ ОПИСАНИЕ отделки',
                subtitle: 'Заполните, пожалуйста, поля.',
                fields: {
                    name_lbl: 'Имя',
                    phone_lbl: 'Телефон',
                    email_lbl: 'Email'
                },
                btn: 'Получить'
            },
            reg: {
                title: 'регистрация',
                subtitle: 'Уже есть учетная запись?',
                switch_link_lbl: 'Войти',
                fields: {
                    sname_lbl: 'Фамилия',
                    name_lbl: 'Имя',
                    fname_lbl: 'Отчество',
                    phone_lbl: 'Телефон',
                    email_lbl: 'Email',
                    pass_lbl: 'Пароль',
                    pass_rpt_lbl: 'Повторите пароль'
                },
                btn: 'Зарегистрироваться'
            },
            login: {
                title: 'Вход',
                subtitle: 'Нужна учетная запись?',
                switch_link_lbl: 'Зарегистроваться',
                fields: {
                    email_lbl: 'Email',
                    pass_lbl: 'Пароль',
                },
                btn: 'Войти',
                link: 'Забыли пароль?',
                errors: {
                    email: 'Введен некорректный e-mail',
                    phone: 'Введен некорректный телефон',
                    name: 'Введено некорректное имя',
                    date: 'Введена некорректная дата',
                    time: 'Введено некорректное время',
                    mess: 'Сообщение должно быть больше 5 символов'
                }
            },
            recovery: {
                title: 'ЗАБЫЛИ ПАРОЛЬ?',
                subtitle: 'Вы можете восстановить ваш пароль. <br/>Введите email указанный при регистрации:',
                switch_link_lbl: 'Зарегистроваться',
                fields: {
                    email_lbl: 'Email'
                },
                btn: 'отправить запрос'
            },
            info_2: {
                title: 'Спасибо',
                subtitle: 'Ваш запрос на&nbsp;создание личного кабинета отправлен, о&nbsp;статусе проверки сообщим дополнительно.'
            },
            deal: {
                title: 'Запись <br/>на приёмку',
                subtitle: 'Заполните, пожалуйста, поля. <br/>Наши специалисты свяжутся с Вами в ближайшее время.',
                date_lbl: 'Дата',
                date_placeholder: 'дд/мм/гггг',
                time_lbl: 'Время',
                time_placeholder: 'чч:мм',
                btn: 'Отправить запрос'
            }
        },
        lk: {
            profile_lbl: 'Личные данные',
            deals_lbl: 'Детали сделки',
            offers_lbl: 'Специальные предложения',
            surname_lbl: 'Фамилия',
            name_lbl: 'Имя',
            fthname_lbl: 'Отчество',
            phone_lbl: 'Телефон',
            btn_save_lbl: 'Сохранить',
            additional_lbl: 'Дополнительно',
            add_1_lbl: 'Улучшенная отделка квартиры',
            add_2_lbl: 'Мебелирование',
            add_3_lbl: 'Прочее',
            add_4_lbl: 'Примечание',
            btn_order_lbl: 'Заказать',
            empty_list_lbl: 'В данный момент специальные предложения отсутствуют',
            subscribe_lbl: 'Подписка на новости',
            deals: {
                title: 'Основные данные',
                th1: 'наименование объекта',
                th2: 'номер дду',
                th3: 'дата дду',
                th4: 'сумма дду',
                th5: 'номер объекта',
                btn: 'Записаться на приемку'
            }
        }
    },
    en: {
        config: {
            btn1: 'Choose an apartment',
            btn2: 'get a description of the finish',
            btn3: 'Selection <br class="hidden-sm-up"/>by facade',
            btn4: 'Pick <br class="hidden-sm-up"/>by parameters',
            btn5: 'get a consultation',
            file_lbl: 'File size',
            size_lbl: 'Mb',
            file_hint_lbl: 'in PDF format',
            back: 'back',
            panoram_lbl: 'PANORAMA',
            logout_lbl: 'Logout',
            phone_lbl: 'Phone',
            address_lbl: 'Object address',
            ct1: '13 Kostyansky pereulok, Moscow',
            ct2: 'Sales office',
            ct3: 'Daev Plaza BC, 20 Daev pereulok, office 101',
            ct4: 'Worktime',
            ct5: '9:00 - 21:00, weekends 10:00 - 19:00',
            app_choice_lbl: 'Apartment selection',
            app_choice_param_lbl: 'Pick by parameters',
            app_choice_fac_lbl: 'Selection by facade',
            app_fac_arrows_lbl: 'use the arrows <br/>to rotate the facade',
            app_fac_side_lbl: 'Pick <br/>by parameters',
            app_param_side_lbl: 'Choose <br/>by facade',
            app_param_lbl_1: 'Number of bedrooms:',
            app_param_lbl_2: 'Extras:',
            app_param_lbl_3: 'Terrace',
            app_param_lbl_4: 'Two-level <br/>apartments',
            app_param_lbl_5: 'Floor(s):',
            app_param_lbl_6: 'Area (m2):',
            app_param_lbl_7: 'Type',
            app_param_lbl_8: 'Area',
            app_param_lbl_9: 'Number of bedrooms',
            app_param_lbl_10: 'Bedrooms',
            app_param_lbl_11: 'Floor',
            app_param_lbl_12: 'Fireplace',
            load_more_lbl: 'Load more',
            floor_lbl: 'floor',
            floors_lbl: 'floors',
            bedrooms_1_lbl: 'bedroom',
            bedrooms_2_lbl: 'bedrooms',
            bedrooms_3_lbl: 'bedrooms',
            build_lbl: 'Building',
            free_lbl: 'available',
            app_1_lbl: 'apartment',
            app_2_lbl: 'apartments',
            app_3_lbl: 'apartments',
            app_window_lbl: 'windows faced',
            post_year_lbl: 'year',
            error_req_lbl: 'Required field',
            topic_lbl_1: 'Choose message topic',
            topic_lbl_2: 'Chosen topic',
            btn_download_lbl: 'Download building plan',
            btn_price_lbl: 'Get a cost',
            and: 'and',
            llc: 'LLC «Design and construction organization».',
            source_lbl: 'Source',
            update_lbl: 'Update',
            price_lbl: 'Price',
            price_lbl2: 'Lead price'
        },
        nav: {
            item1: 'About the project',
            item2: 'News',
            item3: 'Infrastructure',
            item4: 'Apartments',
            item5: 'Penthouses',
            item6: 'Purchase',
            item7: 'Partners',
            item8: 'Construction progress',
            item9: 'Brokers',
            item10: 'Contacts',
            item11: 'Mass media',
            finish: 'ДОМ СДАН',
        },
        partners: {
            pt1: {
                ct: '(Russia)',
                type: 'Financial partner',
                name: 'Sberbank',
                descr: 'Project financing, <br/>Escrow-accounts',
                text: '<p>Sberbank is the largest player in mortgage lending with a market share of over 50%. You can apply for a mortgage directly in the office of the partner-developer, a unique opportunity for our customers.</p>'
            },
            pt2: {
                ct: '(Netherlands)',
                type: 'Architectural bureau',
                name: 'Building Design',
                descr: 'Architectural and planning <br/>solutions',
                text: '<p>A world-renowned partner in architectural and planning solutions.</p>'
            },
            pt3: {
                ct: '(Netherlands)',
                type: 'Architectural bureau',
                name: 'Wolterinck',
                descr: 'Architectural concept',
                text: '<p>Architectural concept</p>'
            },
            pt4: {
                ct: '(England)',
                type: 'Architectural bureau',
                name: 'Squire & Partners',
                descr: 'Architectural concept',
                text: '<p>Architectural concept</p>'
            },
            pt5: {
                ct: '(Russia)',
                type: 'General Designer',
                name: 'Finproject',
                descr: 'Constructive solutions, <br/>engineering systems, <br/>development of working documentation',
                text: '<p>Constructive solutions, engineering systems, development of working documentation</p>'
            }
        },
        home: {
            address: 'Sretenka, 13 Kostyansky pereulok',
            contacts: 'Sales office: Daev Plaza BC, 20 Daev pereulok, office 101 <br/>Property address: 13 Kostyansky pereulok, Moscow <br/>Phone for contact: <br class="hidden-sm-up"/>7 (495) 260-92-27 <br/>Opening hours: 9:00 - 21:00, weekends 10:00 - 19:00',
            info: {
                descr: 'Residential House Turgenev is&nbsp;located in&nbsp;Moscow city center between Sretenka Street and Turgenevskaya Square. <br/>It&nbsp;is&nbsp;based on&nbsp;the principles of&nbsp;well-respected and independent living. Comfort and calmness are surrounded by&nbsp;quiet alleys. The central location near significant places in&nbsp;Moscow allows you fully enjoy the pleasure of&nbsp;life.',
                item1: {
                    title: '8&nbsp;floors',
                    descr: 'Ground Floor -  <br/>infrastructure floor, 1-8 floors - apartments and penthouses.'
                },
                item2: {
                    title: '118 apartments',
                    descr: '2-6 floors   <br/>classic apartments. <br/>7-8 floors - apartments  <br/>with wood burning fireplaces,  <br/>including duplex penthouses.'
                },
                item3: {
                    title: '167 car parking spaces',
                    descr: '28 motorbike spaces. <br/>2 floors of underground parking.'
                },
                item4: {
                    title: '44 storage rooms',
                    descr: 'on the parking level, equipped with ventilation, fire extinguishing and energy saving systems.'
                }
            },
            topics: {
                item1: {
                    title: 'ABOUT THE PROJECT',
                    descr: 'Philosophy of the residential house. <br/>Description of the exterior, <br/>advantages of the object and finishes. <br/>Project documentation.'
                },
                item2: {
                    title: 'INFRASTRUCTURE',
                    descr: 'A unique location near <br/>Moscow\'s most significant places <br/>that allow you to enjoy life.'
                },
                item3: {
                    title: 'ARCHITECTURE',
                    descr: 'The facade of the first six floors is designed with a combination of timeless materials of sand-white natural stone and patented brass.'
                },
                item4: {
                    title: 'NEWS',
                    descr: 'Photo reports and a description of construction progress. <br/>Project news. <br/>Mention in the media.'
                }
            },
            adv: {
                title: 'Turgenev advantages',
                descr1: 'Five-star hotel <br/>infrastructure',
                descr2: 'Historic center',
                descr3: 'Ready-to-stay apartments <br/>(«Quiet House» concept)',
                descr4: 'New construction',
                descr5: 'International <br/>team of architects',
                descr6: 'A secluded garden <br/>in the heart of Moscow'
            }
        },
        about: {
            history: {
                title: 'History of the neighbourhood',
                title2: 'History',
                text1: '<p>A surprisingly quiet and cozy place in the Moscow city center is located between Sretenka Street and Turgenevskaya Square.</p><p>Sretenka was named after the Sretensky Monastery which is located there. And the monastery was named in honor of the important event in Russian history. It is meeting («Sreteniye» - in Russian) of the miracle-working icon of the Mother of God, which was transferred to Moscow from Vladimir, and saving Moscow from the invasion of Tamerlane in 1395.</p>',
                text2: 'Until Tverskaya Street became part of the way to the Northern capital in the XVIII century, Sretenka was the main Moscow street.',
                text3: '<p>The construction of a new residential building with internal infrastructure was a unique example of territory redevelopment in the center of the capital among the quiet alleys of Moscow.</p><p>Turgenev Square inherited its name from the first public library in Russia, which was built in 1885 by Ivan Sergeyevich Turgenev.</p>',
                text4: '<p>Surprisingly, until the 2010s since its construction the reading room was the only monument to Turgenev in Moscow that also gave its name to the town square.</p><p>Tributing to the history of Moscow city center, the new deluxe residential house between Turgenevskaya Square and Sretenka Street in Kostyansky pereulok was given a significant and obliging name: TURGENEV.</p>'
            },
            about: {
                title: 'About project',
                address: 'Moscow, Central Administrative District, <br class="hidden-sm-up"/>Kostyansky pereulok, <br class="hidden-sm-up"/>13 bld. 1'
            },
            arch: {
                title: 'ARCHITECTURAL CONCEPT',
                title2: 'ARCHITECTURAL CONCEPT',
                title3: 'Architecture',
                descr: 'The external appearance of the residential complex is an example of high-quality modern architecture which is typical for the most expensive areas of European capitals. This architectural language is characterized by a strict facade rhythm, simple and minimalistic forms and at the same time extreme attention to detail.',
                slide1: 'Facade cladding. The facade of the first six floors is designed by a combination of timeless materials such as sand-white natural stone and patent lawn. Floor-to-ceiling panoramic windows is undoubtedly an excellent interior solution.',
                slide2: 'The Turgenev Club House facade is clad with natural stone – Branco Valongo white limestone from Portugal. <br/>Its freeze-thaw resistance is remarkable – 240 cycles. The homogenous beige pliable surface looks natural and solid.',
                slide3: 'For fenestration, the Turgenev Club House uses Schüco AWS 75 frames, a special hidden-sash line. <br/>It makes a succinct appearance, both from the outside and inside, when compared to a standard sash.',
                slide4: 'The hidden sash of the AWS 75 BS.SI system makes more space to ensure a panoramic view, the feeling of open space and more light entering the house. <br/>Its reflection factor is 22%, which maintains sufficient privacy in bright light, while ensuring good visibility from the inside due to a low internal reflection ratio.',
                slide5: 'Pilkington Suncool<sup className="reg">&reg;</sup> One 60/40&nbsp;&mdash; employs a perfect balance of light transmission and sun protection. <br/>Compared to ordinary glass, Pilkington Suncool One 60/40 transmits solar heat by two times less, which allows you to feel comfortable indoors in the summer.',
                slide6: 'Facades on the 7th and 8th floors are cladded with granite Dark Brown, a natural stone of a dark brown tinge, its decorative properties expressively accentuate the strict elegance of the architecture and penthouses’ special status.',
                slide7: 'The teracces in the apartments on the 7th and 8th floors are framed with fences made of durable tempered glass which give levity and airiness to the upper floors of the house. Decorative elements of the terracces cladding are performed in patinated brass colour, consonantly matching both the glass of the fence and the façade stone.',
            },
            garden: {
                title: 'A SECLUDED GARDEN IN THE HEART OF MOSCOW',
                title2: 'GARDEN',
                descr: 'Enclosed, guarded garden area is fully available to residents. Complex corteyard is a real spacious oasis with green lawns in the city center. There are landscape lighting, seating areas, paved paths, outdoor lounge area and the crowns of large trees closing over the water channels. The territory of the garden has WI-FI and charging points for mobile devices.'
            },
            finish: {
                title: 'FINISHING',
                descr: 'The realization of the apartments with the final finishing allows you to meet the concept of READY FOR LIVING. <br/>Ready-made solutions are thought out to the smallest detail and include everything necessary for the most comfortable living.'
            },
            lobby: {
                title: 'A lobby',
                descr: '<p>A lobby, restaurant, cinema, wine and cigar rooms and a wellness and fitness area together create the perfect collection of residential facilities.</p><p>The concierge service and a variety of commercial facilities, complete the picture in TURGENEV residential house, providing residents with everything they need for a comfortable and pleasurable life.</p>'
            },
            fitness: {
                title: 'Fitness',
                title_big: 'Fitness, Wellness & SPA',
                descr: '<p>В&nbsp;клубном доме TURGENEV предусмотрено все необходимое для поддержания здорового образа жизни. Около 800м2 инфраструктурного этажа занимает обширная зона фитнеса, велнеса и&nbsp;SPA, которая доступна только для резидентов. Занятия спортом в&nbsp;качественно оборудованной фитнес-зоне или занятия в&nbsp;зале для йоги, прохождение гидротермальной дорожки Кнейпа, посещение сауны или хаммама, спортивный массаж или расслабляющие SPA-процедуры в&nbsp;массажном кабинете, плавание в&nbsp;бассейне&nbsp;&mdash; все это станет приятной ежедневной рутиной для жителей клубного дома TURGENEV. Чтобы посетить эту зону, не&nbsp;придется покидать пределов здания&nbsp;&mdash; можно спуститься на&nbsp;лифте на&nbsp;инфраструктурный этаж и&nbsp;пройти по&nbsp;переходной галерее, которая соединяет все пять секций клубного дома, а&nbsp;бесконтактная технология Face ID&nbsp;сделает посещение максимально комфортным&nbsp;&mdash; магнитные карты и&nbsp;ключи больше не&nbsp;нужны.</p>'
            },
            eng: {
                title: 'ENGINEERING',
                descr: 'The engineering of the project includes the most modern technologies of comfort and safety.',
                item1: {
                    title: 'VENTILATION AND AIR CONDITIONING',
                    descr: 'Supply and exhaust ventilation is implemented on equipment from the Italian concern "Euroclima". The air supplied to the apartment is pre-treated with centralized two-stage cleaning, heating or cooling, then humidified and disinfected with ultraviolet radiation. Fresh and moistened air preserves the beauty of the hair and elasticity of the skin, reduces the likelihood of allergic reactions, facilitates breathing, reduces the manifestations of static electricity, ensures safety paintings and books, wooden interior items, furniture and floors. The VRV system from the Japanese Daikin concern, the world leader in the production of air conditioning systems, is responsible for maintaining a comfortable temperature in each room.'
                },
                item2: {
                    title: 'WATER SUPPLY <br/>AND SEWERAGE',
                    descr: 'The centralized water treatment system filters the water to the "spring" level. The water entering the house is subjected to mechanical filtration, thin (up to 20 microns) and sorption purification (improves the organoleptic characteristics of water – taste, smell and appearance), softens, ionizes and undergoes antibacterial treatment with UV emitters. Food prepared with the use of such water and water procedures are good for health. The water supply system is made of stainless steel pipes of the Swiss company Geberit. The service life of the pipes used is at least 100 years. To provide the residents of the house with uninterrupted hot water supply, boilers with a total volume of 6000 are installed on the 3rd floor in an individual heating point (ITP) liters. The silent sewage system is made using polymer REHAU pipes that meet the highest hygienic standards. Pumps used in water supply and sanitation systems are from the Danish manufacturer Grundfos.'
                },
                item3: {
                    title: 'POWER SUPPLY',
                    descr: 'Reliable power supply of the entire complex is provided by two independent mutually redundant power sources. To protect electrical appliances from the harmful effects of overvoltage, surge limiters are installed at the input to each section of the main switchboard (GRSH) and input-distribution devices (VRU). The input and output switches on the GRSCH and VRU are selected in compliance with selectivity and with protection against short circuits.'
                },
                item4: {
                    title: 'ELEVATORS',
                    descr: 'Elevator equipment of the project involves the installation of European-made elevators with individually designed cabins without engine room. Silent elevators, ready to deliver the residents of the house directly from the door to the car in the underground parking area. Contactless calling panel is installed (Schindler PORT Technology), which allows to save the personal data of passengers. Schindler elevators have proven to be leaders in quality, reliability and high consumer characteristics (quietness, fast door opening).'
                }
            },
            srv: {
                title: 'SERVICES',
                descr: 'The management company incorporates the latest in comfort and safety technology',
                item1: '24-hour security of the complex and organization of access passes',
                item2: 'Access control, control of movement on the territory',
                item3: 'Daily reusable cleaning of entry groups and elevator halls',
                item4: 'Dispatcher\'s «on-line» control of all engineering systems',
                item5: 'Round-the-clock service of electricians, plumbers and elevators',
                item6: 'Daily cleaning of the territory, garbage collection and cleaning and snow removal in winter',
                item7: 'Improvement and care of the territory landscape design',
                item8: 'Apartments cleaning service',
                item9: 'Childcare',
                item10: 'Video surveillance throughout the area (exterior / interior, parking, courtyard, public areas, rooms)',
                item11: 'Concierge services at any time to help residents, meet and greet guests, deliver correspondence',
                item12: 'Personal chef and food delivery services',
                item13: 'Personal driver services',
            },
            safe: {
                title: 'SAFETY',
                text1: '<p>A comprehensive round-the-clock security system is responsible for the high level of the residents safety. The territory of the complex is fenced and equipped with personal access control systems with FaceID technology, registration of entry and exit, as well as video intercom.</p><p>The video surveillance system allows round-the-clock control of the building\'s exterior perimeter, all building entrances, entrances to the territory, and driveways in the underground garages. FaceID technology will also make it possible to organize convenient logistics for residents and in the public areas of the complex (there is no need to bring a chip or magnetic card to the fitness area).</p>',
                text2: '<p>Residents will be connected to an intelligent apartment management system. Data on the status of systems and meter readings are displayed on a single remote control or owner\'s smartphone. Provision is made for temperature regulation, water leakage control, control of the intensity of lighting, the position of curtains and etc.</p>'
            },
            comfort: {
                title: 'COMFORT',
                descr: 'The shared ownership of the internal infrastructure of the house (Sharing Economy) allows you to use the living space for its main functional purpose. There is no need to overpay for "extra" meters. The proposed set of public spaces and their functionality meets the most demanding needs.',
                item1: 'Spa & Wellness',
                item2: 'Fitness Zone',
                item3: 'Room-transformer <br/>for private events',
                item4: 'Tasting <br/>room',
                item5: 'Restaurant',
                item6: 'Chargers <br/>for electric cars',
                item7: 'Cigar room with drawers <br/>for storing cigars',
                item8: 'Two-level <br/>underground parking',
                item9: 'Cinema <br/>seating 12',
                item10: 'Storage <br/>rooms',
                item11: 'Cozy lobby <br/>and lounge area',
                item12: 'Place <br/>for catering'
            }
        },
        apps: {
            title: 'Apartments',
            descr: 'Fundamental in the design of residential premises were the principles of maximum comfort living, functionality of the premises, status, creation of private areas for all family members, as well as the balance of the apartment.',
            subtitle: 'The work of Dutch architects resulted in the most successful layout solutions, which provides everything to make you feel most comfortable.',
            list1: 'Spacious kitchen-living area (50-60 m2)',
            list2: 'Spacious bathrooms in the master bedrooms (10-12 m2)',
            list3: 'Private area in the master bedroom (suite-zone including bedroom, dressing room, bathroom)',
            list4: 'Increased number of windows in family areas of apartments (up to 4 pcs).',
            list5: 'French balconies and bay windows.',
            list6: 'Variety of layouts, predominance of "family" formats in the apartmentography of the project.',
            list7: 'Luxury penthouses with terraces and maintained roofs on the top floors',
            text1: '<p>At the minus 2nd level of parking for storage of personal belongings are storerooms (area of 4-6 m2) with easy access for residents. Equipped with engineering communications (electricity, ventilation), video surveillance systems.</p><p>The presence of storage rooms in the complex allows you to exploit the area of the apartment for its main purpose, not cluttering it and not overpaying for excessive storage space in the apartment.</p><p>Storage rooms in the complex allows to exploit the area of the apartment for its main purpose, not cluttering it and not overpaying for excessive storage space in the apartment.</p>',
            app_param_subtitle: 'We sell apartments with final finishing, according to the READY FOR LIVING concept. Ready-made solutions are thought out in detail and include everything you need to make your life most comfortable.'
        },
        pent: {
            title1: 'PENTHOUSES',
            descr1: 'The penthouses of residential house Turgenev are designed for those who appreciate uncompromising comfort, the leading international architectural firms Wolterinck and Squire & Partners have created a refined and functional design in a modern European style. The quality of materials and textures is of the utmost importance. Every detail has been thought out in advance so that you enjoy comfort from the first day of your stay.',
            title2: '3-D TOUR OF THE PENTHOUSE',
            descr2: 'A private terrace opens up a lot of opportunities: arrange yoga at dawn, invite friends for a barbecue or enjoy dinner with a glass of wine looking at the historic center of Moscow. Chistye Ponds, Turgenevskaya Square, Sretensky Monastery, which are the most central places of the capital, are seen at glance, opening for you from a new side.',
            slide1: 'Exclusive 8th-floor lot owners will have access to their own roof terrace right from their apartment. The terraces are designed by the Dutch studio Wolterinck.',
            slide2: 'The terraces will have their own private little garden with hardscape, water features, fountains, trees, shrubs and flowers, which harmoniously blends with the garden in the yard of the Residential house TURGENEV, also designed by the Wolterinck studio.',
            slide3: 'Terrace space is zoned in a balanced manner. It has a relax zone with soft sofas and tables surrounded by plants, a kitchen island, and a glazed summer dining room.',
            slide32: 'Along the entire perimeter of the terrace there are outdoor lights and landscape lighting that will make it cozy in the evening.',
            slide4: 'On your personal terrace, you can enjoy numerous pleasures - have a yoga session at dawn, invite friends for BBQ or have a savory supper with a glass of wine while overlooking the historic center of Moscow.',
            slide5: 'The terraces of each penthouse overlook several sides, where you can implement different ideas: put sun loungers on the south side to sunbathe during the day, and a table with comfortable sofas on the west side to enjoy the sunset during dinner.',
            slide6: 'Chistye Prudy, Turgenevskaya Square, Sretenskiy Monastery - the very center of the Russian capital is at arm’s length, opening up from a new angle.',
            slide7: 'The open-to-below gives the space lightness and roominess, making the dining room your favorite place in your apartment.',
            slide8: 'The floors in the apartments are covered with Austrian parquet boards. This durable and reliable material is a laconic and sophisticated complement to the interior, and the wood texture is so warm and pleasant to the touch.',
            slide9: 'The entire space of the two-level penthouse is functional. The open corridor on the upper floor will be another chill-out space where you can enjoy the visual aesthetics to the fullest.',
            slide10: 'The facades of the kitchens on the 7th and 8th floors will be made of gray oak veneer.',
            slide11: 'Great attention is paid to the quality of materials and to the textures. Every detail has been elaborated so that you enjoy comfort from the first day of your living here.',
            slide12: 'Deep revitalizing sleep is guaranteed by clean and humidified air, which in TURGENEV is provided by the supply and exhaust ventilation by the Italian company Euroclima and the air conditioning system from the Japanese brand Daikin. In the bedroom, you can set the temperature – 19-20 degrees is considered best for deep sleep.',
            slide13: 'Bathrooms are made of volcanic stone by the German company Knief & Co. The oval shape of the baths harmoniously integrates into the design concept and the space, and their perfect ergonomics will give you comfort in the course of use.',
            slide14: 'In the decoration of bathrooms and toilet facilities, genuine marble of natural shades is used – milky Pirgon with gray veins, and light gray Wooden White with a wood-like texture.',
            slide15: 'Panoramic windows (up to 4 in the apartment’s family area) offer a good view of the Moscow historic center, while filling the entire space with sunlight. The open below drawing room makes it the most bright, spacious and lovable place.',
            slide16: 'At nights, the perfect lighting in the room is maintained by built-in systems. The wood-burning fireplace will make the air warm, comfortable and cozy.',
            slide17: 'Leading international bureaus Wolterinck and Squire & Partners have created an exquisite and functional design in a modern European style.',
            slide18: 'Our solutions are completely ready-to-live and can satisfy the most demanding buyers, while excluding long repairs and noisy reconstructions. You can live a comfortable life as soon as you purchase the apartment.',
            slide19: 'The architectural solution makes the space as well thought out as possible. Each penthouse has a family area - living room, kitchen, bedroom, walk-in wardrobe and bathroom.',
            slide20: 'For those who appreciate uncompromising comfort, we offer the 24/7 concierge service. You can use a personal chef , driver, cleaner or childcare specialist.',
            slide21: 'The spacious bathroom, decorated in a minimalist style, leaves room for sunlight: and in addition to panoramic windows, the penthouse owners will get more natural light from the roof window. At night you can admire the starry sky.',
            slide22: 'It is undoubtedly important to have a place of peace, quietness and tranquility. The TURGENEV Club House concept intends that the apartments are sold with the best-quality finishing already done.',
            slide23: 'From the very first day, you will be surrounded by peace and tranquility. We sell apartments fully finished to comply with the Quiet House concept, which completely excludes a long period (statistics suggest 5 to 8 years from the house commissioning) when the apartments are bought and finished by the owners.',
            slide24: 'The absolute trend of recent years is rectangular rooms with smart zoning, i.e. conditional division of the entire apartment space into public and private areas. All TURGENEV lots offer a spacious – 50-60 sq. m. – common area, namely kitchen and dining room, where it is always a pleasure to gather for family dinners or meet guests, and the corridors lead to the private areas, such as bedrooms, dressing rooms, and bathrooms.',
            slide25: 'Thanks to the wide area of the dining rooms, there will be a place for everything: from the bar where you can drink a cup of coffee, to the wine cabinet with your personal collection of favorite wines.',
            list_title: 'Building PLANS',
            list: {
                txt1: 'PENTHOUSE',
                txt2: 'TOTAL AREA',
                txt2br: 'TOTAL <br/>AREA',
                txt3: 'LEVEL',
                txt4: 'FLOOR',
                txt5: 'FLOOR AREA <br/>APARTMENTS <br/>WITHOUT GALLERIES',
                txt6: 'Entrance hall',
                txt7: 'Living room',
                txt8: 'Library',
                txt9: 'Laundry room',
                txt10: 'Kitchen-dining room',
                txt11: 'Pantry',
                txt12: '3/4 bathroom',
                txt13: 'Passage',
                txt14: 'Bedroom',
                txt15: 'Bathroom',
                txt16: '* the area with a decreasing coefficient is indicated',
                txt17: 'Species characteristics:',
                txt18: 'CHISTYE PRUDY',
                txt19: 'SOVREMENNIK',
                txt20: 'm',
                txt21: 'Kitchen',
                txt22: 'Home office',
                txt23: 'Dining room',
                txt24: 'Terrace',
                txt25: '* the actual area is indicated',
                txt26: 'FLOOR AREA <br/>APARTMENTS <br/>WITHOUT TERRACE',
                txt27: 'Dressing room',
                txt28: 'CHURCH OF ST. NICHOLAS',
                txt29: 'Dining room',
                txt30: 'ROOF TERRACE',
                txt31: 'Roof terrace',
                txt32: 'Entry-hall',
                txt34: 'Total area',
                txt34br: 'Total <br/>area',
                txt35: 'Ligth portals <br/>in the ceiling',
                txt36: '3 BEDROOMS, LIVING ROOM AND CABINET',
                txt37: 'Kitchen-living room',
                txt38: 'Bedroom',
                txt39: '2 BEDROOMS, LIVING ROOM',
                txt40: 'Total area',
                txt41: 'Area without galleries',
                txt42: 'Ladder',
            }
        },
        buy: {
            title1: 'STATUS OF APARTMENTS',
            title2: 'INSTALLMENTS',
            title3: 'MORTAGE',
            title4: 'SALES AND PURCHASE AGREEMENT (SPA)',
            descr1: 'After the completion of construction and commissioning of the Residential House TURGENEV in December 2023, all apartments will be registered as the property of the developer.',
            descr2: 'Apartments in the residential house Tyrgenev can be purchased in installments. Payments can be made on a monthly basis.',
            descr4: 'Transactions with clients are conducted under a sales and purchase agreement (SPA), which ensures the legal purity and security of transactions.'
        },
        brokers: {
            title: 'Dear Brokers!',
            descr1: 'We invite you to cooperate on the sale of apartments in the finished residential delux complex. We always welcome new partners such as real estate agencies and private brokers. The advantages of working with us:',
            descr2: 'For cooperation, please contact <a href="mailto:nepeyvoda@turgenevdom.ru">nepeyvoda@turgenevdom.ru</a>.',
            list1: 'Providing all advertising materials for the project',
            list2: 'Providing information about the progress of the transaction with the client',
            list3: 'Arranging regular broker tours for your representatives',
        },
        infra: {
            title: 'Infrastructure',
            subtitle1: 'External',
            subtitle2: 'Internal',
            descr: '<p>Residential houseTurgenev is located in Moscow city center between Sretenka Street and Turgenevskaya Square. It is based on the principles of respectable and secluded living. Comfort and calmness are surrounded by quiet alleys. The central location near significant places in Moscow allows you fully enjoy the pleasure of life.</p>',
            out: {
                cat1: 'Parks and squares',
                cat2: 'Theaters',
                cat3: 'Museums',
                cat4: 'Restaurants',
                cat5: 'Sport',
                cat6: 'Shopping centers',
                cat7: 'Education',
                cat8: 'The medicine',
                place1: {
                    title: 'Sretensky Boulevard',
                    descr: 'A picturesque boulevard in the Moscow city center, running from Sretenskiye Vorota square to Turgenevskaya square. It is surrounded by greenery, romantic atmosphere and coziness. To take a walk in the evening, listen to the sounds of nature and enjoy a quiet moment is an excellent rest from noisy everyday life.'
                },
                place2: {
                    title: 'Ilyinsky Square',
                    descr: 'One of the oldest squares, located in the center of a noisy city. It is a peace and quiet green island with a long history. Read a book on a bench, listen to music or a podcast, relax and you will immediately feel how the urban bustle disappears.'
                },
                place3: {
                    title: 'Gorka Park',
                    descr: 'An unusual park with a multi-tiered area, where residents and guests of the capital will be able to brighten up their leisure time. The recreation area is located in the center of Moscow and was created at the initiative of local residents in 2017.'
                },
                place4: {
                    title: 'Hermitage Garden',
                    descr: 'In this garden you can feel how art is everywhere in the air. And this is for a reason. The Hermitage was founded by the theatrical figure Ya. V. Shchukin, so there are theaters and stages in the park. They remember the historical performances of famous artists in Russia and abroad.'
                },
                place5: {
                    title: 'Tsvetnoy Boulevard',
                    descr: 'There are a street with a rich historical past not far from Kremlin. This place, which connects the Boulevard Ring with the Garden Ring, allows Moscow residents and visitors to travel back in time by walking along ancient buildings.'
                },
                place6: {
                    title: 'Zaryadye Park',
                    descr: 'Non-standard architectural solutions and impressive achievements of landscape design will not leave you untouched. On the one hand this is a futuristic place with a "floating" bridge over the Moskva-River and on the other hand a real avenue with plants from four natural zones of Russia.'
                },
                place7: {
                    title: 'Alexandrovsky Garden',
                    descr: 'The Upper, Middle and Lower Gardens are a harmonious interweaving of ancient history and nature, majestic architecture and a spiritual atmosphere. Walking through the garden, you will feel like the main character of a novel from the 19th century, because the views opening before you seem to have escaped from the pages of the book.'
                },
                place8: {
                    title: 'Chistye prudy',
                    descr: 'This place holds a variety of moods. Old Moscow with its squares, alleys and architectural monuments will bring peace and calmness. And the pulsating rhythm of the metropolis, the creative and business environment give energy and inner strength. Choose what suits you best.'
                },
                place9: {
                    title: 'Sukharevskaya Square garden',
                    descr: 'A square where green alleys and architecture are harmoniously combined and only complement each other.A circular area, hidden among the trees and resembling a "green office" is the perfect place for your photoshoot.  And any walk or morning jog will be even more pleasant among such picturesque views.'
                },
                place10: {
                    title: 'Moscow City Council Park. Or "Catherine\'s Hospital".',
                    descr: 'A small green corner on Strastnoy Boulevard is a combination of refined aristocracy and a great place to spend your leisure time. The park has emerald lawns and laconic flowerbeds, which would easily inspire an artist to paint a beautiful picture.'
                },
                place11: {
                    title: 'The square on Strastnoy Boulevard',
                    descr: 'As you walk along the century-old trees, enjoy their beauty and the pleasant feeling of antiquity, you can witness a real chess competition. Several generations converge here, all united by the same game. Try to make your own royal gambit.'
                },
                place12: {
                    title: 'Oleg Tabakov Theater',
                    descr: 'The theater is named after the talented actor and director Oleg Tabakov. It is known for its repertoire not only in Russia but also abroad. Famous actors of theater and film perform on this stage, and you have a chance to witness a living history.'
                },
                place13: {
                    title: 'Mayakovsky Theater',
                    descr: 'One of the oldest and most famous theater groups in Russia. The names of outstanding directors, actors, artists, who made the history of the, live in the memory of generations and rightfully fill the pages of books, articles and reviews. Once there, you will forever let this theater into your heart.'
                },
                place14: {
                    title: 'The Bolshoi Theater',
                    descr: 'This is the heart of Russian culture and art, where people come from all over the world to watch legendary live performances. The theater satisfies the viewer\'s need for classical art, enlightens and shapes taste. Here you will feel a part of something special and magical.'
                },
                place15: {
                    title: 'Sovremennik',
                    descr: 'The theater is founded in 1956 and it made its main task to speak the language of the present. «The Sovremennik» preserved this postulate and carried it through the decades for next generations. It is a place where the old begins to speak in a new way, where the connection between the audience and the stage is more valuable than anywhere else.'
                },
                place16: {
                    title: 'Oleg Tabakov Theater',
                    descr: 'The Chistoprydnaya stage of&nbsp;the legendary Oleg Tabakov Theater, where distinguished performances were staged and great talents grew&nbsp;up. As&nbsp;the new season opens, it&nbsp;is&nbsp;highly fascinating to&nbsp;get immersed in&nbsp;the atmosphere of&nbsp;this creative space.'
                },
                place17: {
                    title: 'Visneviy Sad theater',
                    descr: 'Anton Pavlovich Chekhov made an invaluable contribution to Russian literature. His ideas, thoughts, and principles have had many followers, and The Cherry Orchard is among them. The theater invites you to get to know the writer better.'
                },
                place18: {
                    title: 'Dramatic art school',
                    descr: 'A place with the concept of a laboratory theater, where the priority is research with the possibility of constant experimentation. Compared to other theaters, this makes it special and attracts viewers. Visiting any performance, you will be immersed in a world of a completely new and different art.'
                },
                place19: {
                    title: 'Maly Theater',
                    descr: 'The oldest theater in Russia, where the modern generation of artists and directors is distinguished by its commitment to its rich traditions. If you want to experience the real theatrical atmosphere, then hurry to buy a ticket, because they are instantly sold out.'
                },
                place20: {
                    title: 'El cetera',
                    descr: 'This is a live open theater that exists outside of any dogmas and rigid ideas and is not afraid to discover new names, theatrical ideas and forms. It is modern, interesting and unexpected. In this theater you will be overwhelmed by a variety of different bright emotions.'
                },
                place21: {
                    title: 'Manege',
                    descr: 'One of the main exhibition halls of the capital invites you to experience exhibitions and fairs from all corners of our country. Witnessing a long history, the Manege building is still at the center of events today.'
                },
                place22: {
                    title: 'X-Fit Chistye Prudy',
                    descr: 'A highly reputable fitness club in the heart of Moscow offers you a unique training system and exercise equipment from the world\'s leading brands. No matter what your goal is. It could be losing weight, gaining muscle mass or maintaining tone but anyway you will be helped to achieve them.'
                },
                place23: {
                    title: 'World Class',
                    descr: 'This is a place of power in the center of Moscow for those who want to feel the world of conscious physical practices with the best experts in the field. It\'s a space where everything is conducive to rebooting and forgetting about the daily hustle and bustle.'
                },
                place24: {
                    title: 'Savoy Chistye Prudy',
                    descr: 'A modern wellness club in the Moscow city center offers comfort and attention to detail combined with an equipped gym. The result is the perfect atmosphere for you to work out. And that is what you need to recover your strength.'
                },
                place25: {
                    title: 'GUM',
                    descr: 'One of&nbsp;the most famous department stores not only in&nbsp;Russia, but in&nbsp;the entire post-Soviet space. It&nbsp;is&nbsp;not just another fashionable shopping mall but a&nbsp;real object of&nbsp;art, and we&nbsp;mean both its interior and exterior. Designed in&nbsp;a&nbsp;pseudo-Russian style, the building has become as&nbsp;much a&nbsp;symbol of&nbsp;Russia as&nbsp;St. Basil&rsquo;s Cathedral or&nbsp;the Kremlin.'
                },
                place26: {
                    title: 'The Central Children\'s Store',
                    descr: 'Children from all over the country dream of coming here at least once. In this fabulous department store on Lubyanka any child can feel happier, because all the children\'s stores with a variety of toys are gathered here.'
                },
                place27: {
                    title: 'Central Market',
                    descr: 'The word "quality" is truly synonymous with this market. Farm products from the best suppliers. You can come there not for the food only, but also for the leisure. Whether it\'s a family breakfast or lunch with friends after a walk through the center.'
                },
                place28: {
                    title: 'Petrovsky Passage',
                    descr: 'This shopping complex in an elegant 19th-century building gives you the opportunity of elite shopping in the middle of aristocratic interiors. Here you will feel special, delighting yourself with shopping in stores with services of the highest quality.'
                },
                place29: {
                    title: 'Passage Kuznetsky most',
                    descr: 'The building of the shopping center was built in the 19th century in the Empire style. Now it is stylized, preserving the beautiful stucco, showcases and facades. If you go shopping there, you can definitely feel like someone from a noble family.'
                },
                place30: {
                    title: 'Tsvetnoy Department Store',
                    descr: 'A department store in the Moscow city center that you\'ll find hard to walk past without going inside. The peculiarity of the place is that brands are presented in the unique space of the department store. They complement each other and create a special atmosphere that fascinates visitors.'
                },
                place31: {
                    title: 'Pushkin Lyceum №1500',
                    descr: 'The opened lyceum for the 200th anniversary of poet Alexander Pushkin continues the case of the famous Tsarskoye Selo Lyceum. This place educate future specialists to the highest standards.'
                },
                place32: {
                    title: 'Kovshova Foreign Language School',
                    descr: 'This modern school with an in-depth study of languages is special for its accessibility of quality education. At this school your level of English is guaranteed to improve.'
                },
                place33: {
                    title: 'NATIONAL RESEARCH UNIVERSITY HIGHER SCHOOL OF ECONOMICS',
                    descr: 'Ask high school students where they want to enroll, and many will say that this place is a dream. It is a place where studying cannot be boring. And modern approaches to learning and limitless opportunities attract students from all over the country.'
                },
                place34: {
                    title: 'Moscow Architectural Institute',
                    descr: 'The main university of architecture in Russia, with a 250-year history, which train architects and designers of the highest qualification. If you have chosen the path of an architect, there is no better place to learn this profession.'
                },
                place35: {
                    title: 'Lyceum of the National Research University Higher School of Economics',
                    descr: 'Lyceum from the first university of the country already sounds prestigious, in reality it is so too. Taking a high place in the rating of the best schools in Moscow, the lyceum confirms this status by offering special educational programs which are aimed on to reach your potential.'
                },
                place36: {
                    title: 'A. Dumas French Lyceum',
                    descr: 'The Lyceum is under the patronage of the French Embassy and is open to French citizens living in Moscow. Education takes place in the best French traditions, providing Lyceum students with centuries-old quality.'
                },
                place37: {
                    title: 'I. Glazunov Academy of Painting',
                    descr: 'If you love art and would like it to become your life\'s work, consider this university. The academy revives the traditions of fine art. And the teachers and special educational program will make a specialist out of you.'
                },
                place38: {
                    title: 'Университет Управления при Правительстве Москвы',
                    descr: 'Карьера в&nbsp;сфере управлении будет успешной, если в&nbsp;этом вузе, основателем которого является Правительство Москвы. Использование VR&nbsp;технологий разнообразят ваше обучение, а&nbsp;&laquo;двойной диплом&raquo; совместно с&nbsp;Пражским университетом позволит вам носить звание международного специалиста'
                },
                place39: {
                    title: 'Private Lyceum "Modern Education"',
                    descr: 'This lyceum combines modern technology and classical education. Here you will have discipline, responsibility and an invaluable load of knowledge that will help you get into the best universities in Russia.'
                },
                place40: {
                    title: 'TSUM Department Store Moscow',
                    descr: 'The department store has rightly become one of the favorite tourist spots. Once there, you will be amazed by the scale and variety of designs, including hundreds of global brands offering the highest quality products.'
                },
                place41: {
                    title: 'Moscow State University of Management',
                    descr: 'One of Moscow\'s leading universities for training professionals in management, as well as lawyers and international relations specialists. Thanks to the competent teaching staff and curriculum, you will definitely become the best of the best in chosen profession.'
                },
                place42: {
                    title: 'N. V. Sklifosovsky Scientific Research Institute of Emergency Medicine',
                    descr: 'The institute was founded in 1923 on the basis of one of the oldest Moscow hospitals. It receives up to 67,000 patients a year with diseases of varying complexity. Ongoing research and the search for new treatment methods save the lives of thousands of patients across the country.'
                },
                place43: {
                    title: 'RAS Polyclinic',
                    descr: 'The variety of areas of this polyclinic is astounding. From cardiology to neurology highly qualified specialists guarantee you modern and successful treatment methods for even the most complex diseases.'
                },
                place44: {
                    title: 'Sanduny',
                    descr: 'If&nbsp;you want to&nbsp;know what a&nbsp;real Russian bathhouse&nbsp;is, look no&nbsp;further. Sanduny is&nbsp;a&nbsp;place you want to&nbsp;come back to&nbsp;feel how fatigue disappears. It&nbsp;brings complete relaxation and pleasant warmth in&nbsp;every cell of&nbsp;your body.'
                },
                place45: {
                    title: 'Polytechnic Museum',
                    descr: 'One of&nbsp;the world&rsquo;s oldest science and technology museums is&nbsp;located in&nbsp;Moscow in&nbsp;the New Square. It&nbsp;was founded in&nbsp;1872&nbsp;on the initiative of&nbsp;the Society of&nbsp;Lovers of&nbsp;Natural History, Anthropology, and Ethnography, with the active participation of&nbsp;Moscow University professors Grigory Shchurovsky, Anatoly Bogdanov, and August Davidov.'
                },
                place46: {
                    title: 'MMAM',
                    descr: 'After visiting this museum you will look at art in a new way. No one is afraid of self-expression here and museum constantly on the lookout for unique fresh trends in photography and media art.'
                },
                place47: {
                    title: 'Fish of mine',
                    descr: 'Experts say that to&nbsp;try all kinds of&nbsp;fish and seafood, you have to&nbsp;go&nbsp;here every night for two months. We&nbsp;think it&rsquo;s a&nbsp;great idea because it&rsquo;s a&nbsp;short walk from your house to&nbsp;the restaurant.'
                },
                place48: {
                    title: 'Riesling Boyz',
                    descr: 'Dmitry Levitsky and Gosha Karpenko created the Riesling bar in&nbsp;Moscow. During the day the place works as&nbsp;a&nbsp;restaurant of&nbsp;European cuisine, and in&nbsp;the evening&nbsp;&mdash; as&nbsp;a&nbsp;pre-party bar with music played on&nbsp;vinyl.'
                },
                place49: {
                    title: 'Lila',
                    descr: 'A&nbsp;new trendy place is&nbsp;located in&nbsp;a&nbsp;two-story mansion on&nbsp;Sretenka. Here you can enjoy pan-Asian cuisine Lila Asia, Italian cuisine Lila Italy, pamper yourself with sweets in&nbsp;Lila Pastry caf&eacute; and relax in&nbsp;Insider bar.'
                },
                place50: {
                    title: 'Chinese script',
                    descr: 'Another successful project of&nbsp;Alexander Rappoport with the highest positions in&nbsp;all gastronomy rankings of&nbsp;Moscow. Cantonese cuisine in&nbsp;a&nbsp;fresh guise.'
                },
                place51: {
                    title: 'Eclair Claire',
                    descr: 'Cheesecakes, lingonberry pies, Swedish cardamom buns and, of&nbsp;course, eclairs&nbsp;&mdash; one of&nbsp;the nicest and coziest coffee houses is&nbsp;situated near TURGENEV Clubhouse.'
                },
                place52: {
                    title: 'Maurizio',
                    descr: 'Maurizio Pizzuti presents a&nbsp;unique gastronomic project, which is&nbsp;located near the TURGENEV Clubhouse. Here you can enjoy the main pleasures of&nbsp;Italy: traditional cuisine, luxurious wine cellar and confectionery with real Italian desserts.'
                },
                place53: {
                    title: 'Olluco',
                    descr: 'In Peru, Chef Virgilio Martínez’s signature dishes represent his land’s biodiversity and follow the principle ‘There Is Always Something Bigger Beyond’. Olluco is a result of striving to go beyond, explore, and experiment with new ingredients impressing by their quality and origin.'
                }
            },
            in: {
                cat1: {
                    title: 'Lobby',
                    descr: 'You will find yourself in a spacious lobby, where you will feel like a member of the royal family. Then you will be greeted by the porter and he will help you carry your bags or heavy packages. The cozy space with luxurious marble finishes, high ceilings and unique lighting create a special atmosphere of comfort and elegance. And the exquisite design combined with the latest technology and high-class service will leave you speechless.'
                },
                cat2: {
                    title: 'Concierge Service',
                    descr: 'Meet and greet guests, deliver correspondence; pick up cleaning service, personal chef and driver, or even a babysitter for the kids.'
                },
                cat3: {
                    title: 'Fitness',
                    descr: 'Making fitness a regular part of your life is much easier when it\'s available at any time. In TURGENEV club house there will be a private fitness zone with access to strength, cardio and functional training.'
                },
                cat4: {
                    title: 'The pool',
                    descr: 'The two 23-meter long lanes are favorite ritual of every morning. A time when you are on your own. The perfect way to start the day, and after that everything planned for the day can be realized with great enthusiasm.'
                },
                cat5: {
                    title: 'SPA & Welness',
                    descr: 'You can recover after sport and reward yourself for the work done in SPA-center. Sporty and relaxing massages will give you an additional charge of energy, and your body will say "thank you". And while it\'s cold outside you can always warm up in the sauna or relax in the hammam after a hard day.'
                },
                cat6: {
                    title: 'Wine Bar and Cigar Room',
                    descr: 'Our own negotiation room will be a convenient place to conduct the official part of the meeting, and the cigar room is perfect to celebrate the completion of a successful deal.'
                },
                cat7: {
                    title: 'Restaurant',
                    descr: 'Only a few floors separate you from the best gastronomic creations. All you need is to go down and then enjoy the best cuisine. Meetings with friends, partners or just pleasant family evenings in a cozy restaurant without leaving your home. This is especially true in summer. You can enjoy a glass of wine on the open veranda, surrounded by an oasis of greenery in your landscaped garden.'
                },
                cat8: {
                    title: 'Cinema',
                    descr: 'In the TURGENEV club house you will have a personal cinema for 12 seats, where you and your friends can watch your favorite films, as well as a soccer match, which allows you to feel the atmosphere at 100%.'
                },
                cat9: {
                    title: 'Meeting Room',
                    descr: 'A business meeting is more successful when you feel like you\'re on your own territory. And an informal atmosphere helps to win over the interlocutor and consolidate the success of the negotiations.'
                },
                cat10: {
                    title: 'Management Company',
                    descr: 'There is always a big team behind impeccable service, even if you can\'t see it. For your comfort, the management company provides round-the-clock service of electricians, plumbers and elevators, daily cleaning of the territory, trash and snow removal. And also gardeners create a truly magical atmosphere of the courtyard.'
                }
            }
        },
        news: {
            title: 'Project news'
        },
        mass: {
            title: 'Mass media'
        },
        footer: {
            pr_lbl: 'Project «Our House»',
            policy_lbl: 'Privacy policy',
            copyright: 'The contents of this website (including the information and content posted) are copyrighted <br/>(Art. 1271 of the Civil Code of the Russian Federation). It is forbidden to copy the design of this site, its structure and individual elements without prior written consent of Neostroy Design and Construction LLC.',
            decl_lbl: 'Project <br/>Statement',
            const_lbl: 'Construction <br/>Permit',
            state_reg_lbl: 'Certificate <br/>of state registration <br/>of right',
            ddu_reg_lbl: 'DDU (co-investment agreement) draft',
            vvod_reg_lbl: 'Разрешение на&nbsp;ввод объекта в&nbsp;эксплуатацию'
        },
        modals: {
            design: {
                title: 'Get a description of the finish',
                subtitle: 'Please fill in the fields.',
                fields: {
                    name_lbl: 'Name',
                    phone_lbl: 'Phone',
                    email_lbl: 'E-mail'
                },
                btn: 'Get'
            },
            reg: {
                title: 'Registration',
                subtitle: 'Already have an account?',
                switch_link_lbl: 'Login',
                fields: {
                    sname_lbl: 'Surname',
                    name_lbl: 'Name',
                    fname_lbl: 'Patronymic',
                    phone_lbl: 'Phone',
                    email_lbl: 'Email',
                    pass_lbl: 'Password',
                    pass_rpt_lbl: 'Repeat the password'
                },
                btn: 'Register'
            },
            login: {
                title: 'Login',
                subtitle: 'Do you want to create an account?',
                switch_link_lbl: 'Create account',
                fields: {
                    email_lbl: 'Email',
                    pass_lbl: 'Password',
                },
                btn: 'Sign in',
                link: 'Forgot password?',
                errors: {
                    email: 'Incorrect phone E-mail entered',
                    phone: 'Incorrect phone number entered',
                    name: 'Incorrect name entered',
                    date: 'Incorrect date entered',
                    time: 'Incorrect time entered',
                    mess: 'Message should be more than 5 chars'
                }
            },
            recovery: {
                title: 'Forgot password?',
                subtitle: 'You can recover your password. <br/>Enter the email address specified during registration:',
                fields: {
                    email_lbl: 'Email'
                },
                btn: 'Send'
            },
            info_2: {
                title: 'Thank you',
                subtitle: 'Your request to create a personal account has been sent, we will inform you about the verification status additionally.'
            },
            deal: {
                title: 'Registration for the acceptance of an apartment',
                subtitle: 'Please fill in the fields. Our specialists will contact you soon.',
                date_lbl: 'Date',
                date_placeholder: 'dd/mm/yyyy',
                time_lbl: 'Time',
                time_placeholder: 'hh/mm',
                btn: 'Send a request'
            }
        },
        lk: {
            profile_lbl: 'Personal information',
            deals_lbl: 'Deal details',
            offers_lbl: 'Special offers',
            surname_lbl: 'Surname',
            name_lbl: 'Name',
            fthname_lbl: 'Patronymic',
            phone_lbl: 'Phone',
            btn_save_lbl: 'Save',
            additional_lbl: 'Аdditionally',
            add_1_lbl: 'Improved finishing of the apartment',
            add_2_lbl: 'Furniture',
            add_3_lbl: 'Other',
            add_4_lbl: 'Note',
            btn_order_lbl: 'Order',
            empty_list_lbl: 'There are no special offers available for you at the moment',
            subscribe_lbl: 'Subscribe to news',
            deals: {
                title: 'Main data',
                th1: 'Name of the object',
                th2: 'DDU number',
                th3: 'DDU date',
                th4: 'DDU amount',
                th5: 'Object number',
                btn: 'Sign up for an apartment'
            }
        }
    }
}

const I18n = createI18n(
    locales,
    translations,
);

export default I18n;
